
.loader-sp{
background: black;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
}

:root {
    --swiper-theme-color: white !important;
}
button{
    cursor: pointer;
}
svg{
    cursor: pointer;
}