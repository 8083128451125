.CategoryCard{
    height: 385px;
    min-width: 250px;
    /* background-color: aliceblue; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
   
    margin-right: 10px;
    user-select: none;
    position: relative;
}

.CategoryCardUL{
    position: absolute;
    line-height: 20px;
    list-style: none;
    font-size: 13px;
    font-weight: 500;
    color: white;
    margin-top:60px;
    margin-left: 35px;
}

.CategoryCardSpan:last-child{

    position: absolute;
    top: 210px;
    text-decoration: underline #F56000 2px;
}

.CategoryCardSpan:nth-child(4):hover{
    cursor: pointer;
transition: 0.6s;
    color:#F56000;
    text-decoration: underline white 2px;
}

.CategoryCardImage{
    height: 385px;
    min-width: 250px;
    border-radius: 10px;
    filter: brightness(0.5);
}


.CategoryCardLI:hover{
cursor: pointer;
transition: 0.6s;
color: #F56000;
}


