.Forum{
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
}


.articleForum {
    grid-area: content;
    /* background-color: #ffffff; */
    color: white;
    border-radius: 15px;

  }
  .asideForum {
    grid-area: sidebar;
    padding-left: 40px;
  
    border-radius: 15px;
    text-align: center;
  }
  .wrapperForum {
    margin-top: 50px;
    display: grid;
    grid-gap: 5%;
    grid-template-columns: 30% 70%;
    grid-template-areas: 
      "sidebar content"
  }
  @media (max-width: 830px) {
    .wrapperForum {
      grid-template-columns: 4fr;
      grid-template-areas:
       
      "content"
      "sidebar"
    }

    .ForumCard1{
        width: 100%;
    }

    .asideForum {
      display: none;
      }
  }


  @media (max-width: 800px) {
    .ForumCard1{
        width: 100%;
    }
  }