
  .CartContainer {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'lato', sans-serif;
  }
  
  h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
    small {
      font-size: 0.5em;
    }
  }
  
  .responsive-table {
    li {
      border-radius: 15px;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      align-items: center;
    }
    .table-header {
      background-color: #ffffff;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      font-weight: 600;
    }
    .table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    }
    .col-1 {
      flex-basis: 40%;
    }
    .col-2 {
      flex-basis: 20%;
    }
    .col-3 {
      flex-basis: 15%;
    }
    .col-4 {
      flex-basis: 10%;
    }
    
    @media all and (max-width: 767px) {
      .table-header {
        display: none;
      }
  
      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
        
      }
      .col {
        display: flex;
        padding: 10px 0;
        flex-direction: column;
        align-items: center;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
  }