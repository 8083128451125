
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);

:root {
  --dark: #101315;
  --light: #eeeeee;
  --gradient: linear-gradient(10deg, #ffaa00, #ff6a00);
  --gradient2: linear-gradient(15deg, #04ea00, #00d17d);
  --gradient3: linear-gradient(15deg, #b648ff, #ef5dff);
  font-size: 1rem;
}


.modal-button {
  width: 25px;
  height: 20px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 1.25px;
  cursor: default;
  font-family: "Montserrat", sans-serif;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.modal {
  width: clamp(50%, 700px, 90%);
  height: min(400px);
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
}



.orange-gradient {
  background: white;
  z-index: 10;

}

.green-gradient {
  background: white;
}

.pink {
  color: #c273ff;
}

.gray {
  color: #666666;
}

.light-blue {
  color: #00b7ff;
}

.modal-button {
  position: relative;
  bottom: 1.5rem;
  padding: 0 3rem;
  min-height: 3rem;
  margin: auto auto 0 auto;
  background: var(--dark);
  color: var(--light);
}

.save-button {
  padding: 0 1rem;
  margin: 2rem auto auto 0;
  background: var(--gradient);
  color: var(--dark);
}

.close-button {
  padding: 0 2rem;
  height: 2.5rem;
  margin: 2rem auto 1rem 0;
  background: #101111;
  /* border: 1px dashed #9a9a9a99; */
  color: #ffaa00;
  border-radius: 4px;
  transition: background ease 400ms;
  box-shadow: 1px 1px 15px #03030399;
}


.bottom {
  justify-content: flex-end;
}

.top {
  justify-content: flex-start;
}



.notification-text {
  margin: auto auto auto 0;
  padding: 0;
  font-size: 100%;
  font-weight: 600;
  letter-spacing: 0.25px;
  font-family: "Montserrat", sans-serif;
}

.add-button {
  padding: 0 1rem;
  margin: 2rem auto auto 0;
  background: var(--gradient2);
  color: var(--dark);
}

.close {
  height: 1.1rem;
  background: transparent;
  border: none;
  outline: none;
  margin: 0 0 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close svg {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.sub-header {
  margin: 1rem auto 1rem 0;
  color: #9e9e9e;
}

@media screen and (min-width: 960px) {
  .modal-button {
    cursor: pointer;
  }
}


.ModalContainer{
display: flex;
flex-direction: row;
}

.ModalContainerLeft{
  width: 50%;
  background-color: #e2e1e19a;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;

}


.ModalContainerRight{
  width: 50%;
  height: 400px;
  padding-left: 15px;
  padding-top: 15px;
  overflow-y: scroll;
  /* background-color: #9e9e9e; */

}


.ModalContainerLeftImage{
  width: 300px;
  height: 300px;

}
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
.ModalContainerRightSpan{
  font-size: 24px;
  font-weight: bold;
  font-family: 'Patua One', cursive;
  padding-right: 10px

}


.ModalContainerRightStar{
  margin-top: 5px;
  display: flex;
  margin-bottom: 8px;
}

.ModalContainerRightSpan2{
  font-size: 28px;
  color: #F65000;
}


.ModalContainerRightSpan3{
  font-size: 13px;
  color: grey;
}

.ModalContainerRightButtons{
  margin-top: 15px;
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}


.ModalContainerRightButtons1{
  padding: 12px;
  background-color: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.ModalContainerRightButtons1:hover{
  padding: 12px;
  background-color: black;
  color: white;
  border-radius: 5px;
  transform: scale(0.95);
  transition: 0.5s;
}


.ModalContainerRightButtons1PartSpan{
  font-size: 28px;
}


.ModalContainerRightButtons1Part{
display: flex;
align-items: center;
width:fit-content; 
padding-bottom: 5px;
border-bottom: 1px solid black;
}

.ModalContainerRightButtons1Partinput{
  width:45px;
  text-align: center;
  height: 35px;
  outline: none;
  border :none;
  font-size: 18px;
  margin-left: 15px;

}

.ModalContainerRightSpan4{
  font-size: 13px;
font-weight: 400;
color: grey;
display: flex;
align-items: center;
gap: 5px;
cursor: pointer;
transition: 0.4s;
margin-bottom: 12px;
}


.ModalContainerRightSpan4:hover{
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #F65000;
  }

.ModalContainerRightCategory{
  display: flex;

  gap: 5px;
}


  .ModalContainerRightCategorySpan1{
    font-size: 13px;
    /* font-weight: bold; */
  }

  .ModalContainerRightCategorySpan2{
    font-size: 13px;
    color: grey;
    /* font-weight: bold; */
  }