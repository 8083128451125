
@import url('https://fonts.googleapis.com/css?family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css?family=Hind&display=swap');

.SearchBox{
    display: flex;
    justify-content: center;
    width: 100%;
}
:root {
    --rad: .7rem;
    --dur: .3s;
    --color-dark: #2f2f2f;
    --color-light: #fff;
    --color-brand: #f65000;
    --font-fam: 'Lato', sans-serif;
    --height: 3.5rem;
    --btn-width: 6rem;
    --bez: cubic-bezier(0, 0, 0.43, 1.49);
  }
  
  // Setup

  
  // Main styles
  .searchform {
    position: relative;
    width: 80%;
    background: var(--color-brand);
    border-radius: var(--rad);
  }
  .searchinput, .searchbutton {
    height: var(--height);
    font-family: var(--font-fam);
    border: 0;
    color: var(--color-dark);
    font-size: 1.8rem;
  }
  .searchinput[type="search"] {
    outline: 0; // <-- shold probably remove this for better accessibility, adding for demo aesthetics for now.
    width: 100%;
    background: var(--color-light);
    padding: 0 1.6rem;
    border-radius: var(--rad);
    appearance: none; //for iOS input[type="search"] roundedness issue. border-radius alone doesn't work
    transition: all var(--dur) var(--bez);
    transition-property: width, border-radius;
    z-index: 1;
    position: relative;
  }
  .searchbutton {
    display: none; // prevent being able to tab to it
    position: absolute;
    top: 0;
    right: 0;
    width: var(--btn-width);
    font-weight: bold;
    background: var(--color-brand);
    border-radius: 0 var(--rad) var(--rad) 0;
  }
  .searchinput:not(:placeholder-shown) {
    border-radius: var(--rad) 0 0 var(--rad);
    width: calc(100% - var(--btn-width));
    + button {
      display: block;
    }
  }
  .searchlabel {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  