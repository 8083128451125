.ForumCard1{
    user-select: none;
    margin-bottom:100px;
    width: 80%;
}

.ForumCard1IMG{
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.ForumCard1Top{
    display: flex;
    gap: 10px;
    align-items: center;
   
}

.ForumCard1TopP{
    color: #F56000;
    font-size: 13px;
    cursor: pointer;
}

.ForumCard1TopP:hover{
    color: #F56000;
    text-decoration: underline;
    font-size: 13px;
    cursor: pointer;
}

.ForumCard1TopSpan{
    height: 5px;
    width: 5px;
    background-color: white;
}

.ForumCard1TopP2{
    color: lightslategray;
    font-size: 13px;
    cursor: pointer;
}

.ForumCard1P{
    font-family: 'Zuume';
    font-size: 35px;
    font-style: italic;
   
}


.ForumCard1P:hover{
    font-family: 'Zuume';
    font-size: 35px;
    font-style: italic;
    color: #F56000;
    cursor: pointer;
}

.ForumCard1P2{
    color: lightgray;
    font-size: 13px;
}


.ForumCard1Button{
padding: 13px;
margin-top: 15px;
border: none;
border-radius: 5px;
font-weight: bold;
transform: skew(-15deg);
font-style: italic;
cursor: pointer;
}

.ForumCard1Button:hover{
    padding: 13px;
    background-color: #F56000;
    color: white;
    margin-top: 15px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    transform: skew(-15deg);
    font-style: italic;
    cursor: pointer;
    }

