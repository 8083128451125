

#wrap
{
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0 auto 0 auto;
}

#lightings
{
	bottom: -30px;
	position: absolute;
	width: 100%;
}

section
{
	/*border-radius*/
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	height: 0px;
	width: 100%;
	position: relative;
	margin: auto;

}

#one
{
	/*animation*/
	-webkit-animation: one 5s ease-in-out infinite alternate;
	-moz-animation: one 5s ease-in-out infinite alternate;
	-ms-animation: one 5s ease-in-out infinite alternate;
	-o-animation: one 5s ease-in-out infinite alternate;
	animation: one 5s ease-in-out infinite alternate;
}
@-webkit-keyframes 
one { from {
-webkit-box-shadow: 0 0 250px 20px #473C78;
}

to { -webkit-box-shadow: 0 0 100px 15px #F72A3B; }
}
@-moz-keyframes 
one { from {
-moz-box-shadow: 0 0 250px 20px #473C78;
}

to { -moz-box-shadow: 0 0 100px 15px #F72A3B; }
}
@-o-keyframes 
one { from {
-o-box-shadow: 0 0 250px 20px #473C78;
}

to { -o-box-shadow: 0 0 100px 15px #F72A3B; }
}

@keyframes 
one { from {
box-shadow: 0 0 250px 20px #473C78;
}

to {box-shadow: 0 0 100px 15px #F72A3B; }
}

#two
{
	width: 90%;
	/*animation*/
	-webkit-animation: two 4s ease-in-out infinite alternate;
	-moz-animation: two 4s ease-in-out infinite alternate;
	-ms-animation: two 4s ease-in-out infinite alternate;
	-o-animation: two 4s ease-in-out infinite alternate;
	animation: two 4s ease-in-out infinite alternate;
}
@-webkit-keyframes 
two { from {
-webkit-box-shadow: 0 0 250px 20px #18C499;
}

to { -webkit-box-shadow: 0 0 100px 15px #D8F05E; }
}
@-moz-keyframes 
two { from {
-moz-box-shadow: 0 0 250px 20px #18C499;
}

to { -moz-box-shadow: 0 0 100px 15px #D8F05E; }
}
@-o-keyframes 
two { from {
-o-box-shadow: 0 0 250px 20px #18C499;
}

to { -o-box-shadow: 0 0 100px 15px #D8F05E; }
}

@keyframes 
two { from {
box-shadow: 0 0 250px 20px #18C499;
}

to { box-shadow: 0 0 100px 15px #D8F05E; }
}

#three
{
	width: 80%;
	/*animation*/
	-webkit-animation: three 3s ease-in-out infinite alternate;
	-moz-animation: three 3s ease-in-out infinite alternate;
	-ms-animation: three 3s ease-in-out infinite alternate;
	-o-animation: three 3s ease-in-out infinite alternate;
	animation: three 3s ease-in-out infinite alternate;
}
@-webkit-keyframes 
three { from {
-webkit-box-shadow: 0 0 250px 20px #FFDD00;
}

to { -webkit-box-shadow: 0 0 100px 15px #3E33FF; }
}
@-moz-keyframes 
three { from {
-moz-box-shadow: 0 0 250px 20px #FFDD00;
}

to { -moz-box-shadow: 0 0 100px 15px #3E33FF }
}
@-o-keyframes 
three { from {
-o-box-shadow: 0 0 250px 20px #FFDD00;
}

to { -o-box-shadow: 0 0 100px 15px #3E33FF }
}

@keyframes 
three { from {
box-shadow: 0 0 250px 20px #FFDD00;
}

to { box-shadow: 0 0 100px 15px #3E33FF }
}

#four
{
	width: 70%;
	/*animation*/
	-webkit-animation: four 2s ease-in-out infinite alternate;
	-moz-animation: four 2s ease-in-out infinite alternate;
	-ms-animation: four 2s ease-in-out infinite alternate;
	-o-animation: four 2s ease-in-out infinite alternate;
	animation: four 2s ease-in-out infinite alternate;
}
@-webkit-keyframes 
four { from {
-webkit-box-shadow: 0 0 250px 20px #781848;
}

to { -webkit-box-shadow: 0 0 100px 15px #F2BBE9; }
}
@-moz-keyframes 
four { from {
-moz-box-shadow: 0 0 250px 20px #781848;
}

to { -moz-box-shadow: 0 0 100px 15px #F2BBE9; }
}
@-o-keyframes 
four { from {
-o-box-shadow: 0 0 250px 20px #781848;
}

to { -o-box-shadow: 0 0 100px 15px #F2BBE9; }
}

@keyframes 
four { from {
shadow: 0 0 250px 20px #781848;
}

to { shadow: 0 0 100px 15px #F2BBE9; }
}

#five
{
	width: 60%;
	/*animation*/
	-webkit-animation: five 1s ease-in-out infinite alternate;
	-moz-animation: five 1s ease-in-out infinite alternate;
	-ms-animation: five 1s ease-in-out infinite alternate;
	-o-animation: five 1s ease-in-out infinite alternate;
	animation: five 1s ease-in-out infinite alternate;
}
@-webkit-keyframes 
five { from {
-webkit-box-shadow: 0 0 250px 20px #42F2A1;
}

to { -webkit-box-shadow: 0 0 100px 15px #F4F6AD; }
}
@-moz-keyframes 
five { from {
-moz-box-shadow: 0 0 250px 20px #42F2A1;
}

to { -moz-box-shadow: 0 0 100px 15px #F4F6AD; }
}
@-o-keyframes 
five { from {
-o-box-shadow: 0 0 250px 20px #42F2A1;
}

to { -o-box-shadow: 0 0 100px 15px #F4F6AD; }
}

@keyframes 
five { from {
box-shadow: 0 0 250px 20px #42F2A1;
}

to { box-shadow: 0 0 100px 15px #F4F6AD; }
}



.Footer{
    padding-top: 30px;
    margin-top: 70px;
    border-top: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
    user-select: none;
}

.FooterLogo{
    width: 170px;
    height: 170px;
}


.FooterUl{
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    color: white;
    gap: 20px;
    list-style: none;
    justify-content: center;
    align-items: center;
}


.FooterULli{
font-weight: bold;
font-size: 15px;
cursor: pointer;
transition: 0.4s;
}

.FooterULli:hover{
    font-weight: bold;
    font-size: 15px;
    color: #F65000;
    text-decoration: underline;
    }

.FooterCopyrightP{
    margin-top: 30px;
    color: white;
    font-weight: bold;
    font-size: 13px;
}







