.take-h {
  color: white;
  font-family: "Lato", Arial, sans-serif;
}
/* .take-h-1{

} */

.left-h-t {
  
 width: 80%;

 }
.take-h1 {
  color: #464646;
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
}
.take-h1:hover {
  color: #f65000;
}
.take-h2 {
  cursor: pointer;
  color: #464646;
  font-size: 15px;
  font-weight: 100;
}
.take-h2:hover {
  color: #f65000;
}
.take-h3 {
  font-size: 15px;
  font-weight: 100;
}
.take-h-1 {
  flex-wrap: wrap;
  display: flex;
  margin: 30px;
  gap: 6px;
}
.span-arro1 {
  display: flex;
  align-items: center;
}
.span-arro2 {
  display: flex;
  align-items: center;
}
.take-sppa {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: -4px 10px 0;
  vertical-align: middle;
  background-color: #ccc;
  transform: skewX(-15deg);
}
.take-h-4 {
  display: flex;
  align-items: center;
}
.take-h4 {
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
}
.take-h4:hover {
  color: #f65000;
  text-decoration: underline #f65000;
}
.take-h5 {
  color: #464646;

  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
}
.take-h5:hover {
  color: #f65000;
  text-decoration: underline #f65000;
}
.take-h6 {
  font-size: 40px;
  font-family: "Odibee Sans", cursive;
  font-style: italic;
  display: flex;

  text-transform: uppercase;
}

.take-r1 {
  display: flex;
  justify-content: center;
  width: 70%;
  /* padding: 0px 29px; */
}
.take-img-m {
  height: auto;
  max-width: 100%;
  width: 100%;
  display: block;
}
.take-h-5 {
  margin: 30px 0px;
}
.take-l {
  display: flex;
  justify-content: center;
}
.para-take {
  margin-top: 30px;
}
.para-take2 {
  margin-top: 30px;
}
.take-para-1 {
  color: #464646;
  padding: 15px 0px;
  line-height: 1.5;
  font-size: 17px;
}
.take-para-2 {
  color: #464646;
  padding: 5px 0px;
  line-height: 1.5;
  font-size: 17px;
}
.take-para-3 {
  color: #464646;
  padding: 5px 0px;
  line-height: 1.5;
  font-size: 17px;
}
.take-para-4 {
  color: #464646;
  padding: 15px 0px;
  line-height: 1.5;
  font-size: 17px;
}
.take-para-5 {
  color: #464646;
  padding: 5px 0px;
  line-height: 1.5;
  font-size: 17px;
}
.take-para-6 {
  color: #464646;
  padding: 5px 0px;
  line-height: 1.5;
  font-size: 17px;
}
.span-take-2 {
  color: white;
  font-size: 32px;
}
.take-h7 {
  font-style: italic;
  font-size: 20px;
  font-weight: 100;
}
.take-h-6 {
  margin-top: 5px;
}
.take-h-7 {
  display: flex;
  gap: 1rem;
  align-items: baseline;
}
.span-take-3 {
  color: #f65000;

  font-size: 40px;
}
.take-h8 {
  font-style: italic;
  font-size: 16px;
  font-weight: 100;
  margin: 13px 42px;
  color: #464646;
}
.take-h-9 {
  display: flex;
  /* gap: 1rem; */
  margin-top: 20px;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}
.take-im-g {
  height: auto;
  max-width: 100%;
  display: block;
}
.take-im-g1 {
  height: auto;
  max-width: 100%;
  display: block;
}
.take-ul-h {
  padding: 21px 40px;
}
.take-ul-1 {
  color: #464646;

  line-height: 1.5;
  font-size: 17px;
}
.para-8 {
  color: #464646;

  line-height: 1.5;
  font-size: 17px;
}
.take-btn-h {
  padding: 6px 16px;
  transform: skewX(-10deg) scaleX(0.955);
  border: none;
  border-radius: 3px;
  background-color: white;
  color: black;
}
.take-btn-h:hover {
  background-color: #f65000;
  color: white;
}

.take-b-hh {
  display: flex;
  gap: 10px;
  padding: 7px 26px;
}
.abou-bb {
  display: flex;
  gap: 16px;
  align-items: center;
}

.take-h11 {
  color: black;
  font-size: 35px;
  font-weight: 100;
  cursor: pointer;
  text-transform: uppercase;
  width: fit-content;
  font-style: italic;
  font-family: "Odibee Sans", cursive;
}
.take-h11:hover {
  color: #f65000;
}

.para-p7 {
  color: #464646;
  line-height: 1.5;
  font-size: 17px;
}
.border-take-unknown {
  margin-top: 50px;
  border: 2px solid;
  padding: 29px 42px;
  background: white;
}

.unknown-p-img {
  width: 123px;
}
.abou-bb1 {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}
.take-h12 {
  color: #464646;
  font-size: 15px;
  font-weight: 100;
  cursor: pointer;
  text-transform: uppercase;
}
.para-p8 {
  color: #464646;
  line-height: 1.5;
  font-size: 17px;
  cursor: pointer;
}
.para-p8:hover {
  color: #f65000;
}
.span-take7 {
  display: flex;
  align-items: center;
}
.spa-uu7 {
  display: flex;
  align-items: center;
  color: #464646;
}
.unknown-p-img11 {
  height: auto;
  max-width: 100%;
  display: block;
}

@media only screen and (max-width: 800px) {
  .for-o-take {
    gap: 1rem;
    flex-direction: column;
  }
  .take-r1 {
    display: flex;
    justify-content: center;
    width: 90%;
    padding: 0px 0px;
  }
  .take-b-hh {
    display: flex;
    gap: 10px;
    /* padding: 7px 26px; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .comment-sec-take {
    display: flex;
    align-items: unset;
    width: calc(100% - 80px);
    justify-content: space-between;
    border: 2px solid transparent;
    background: white;
    padding: 30px;
  }

  .take-h11 {
    width: auto;
  }
}
@media only screen and (max-width: 900px) {
  .comment-sec-take {
    align-items: unset !important;
  }
  .take-l {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 500px) {
  .comment-sec-take {
    flex-wrap: wrap;
  }

  .comment-sec-take2 {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1292px) {
  .take-h-9 {
    display: flex;
    gap: 1rem;
    margin-top: 20px;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
}

.take-hr-1 {
  margin: 20px;
}

@media only screen and (max-width: 550px) {
  .abou-bb {
    text-align: center;
    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.take-h13 {
  font-size: 40px;
  font-family: "Odibee Sans", cursive;
  font-style: italic;
  display: flex;
  text-transform: uppercase;
  margin: 10px 0px;
}
.unknown-p-img2 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  margin-right: 20px;
}
.take-h14 {
  color: black;
  font-style: normal;
  clear: both;
  display: block;
  font-weight: 100;
  font-size: 24px;
  /* line-height: 1; */
}
.comment-date {
  color: #464646;
  line-height: 1.5;
  font-size: 15px;
  cursor: pointer;
  font-weight: 100;
}
.comment-1-take {
  align-items: center;
  display: flex;
}
.comment-sec-take {
  display: flex;
  align-items: center;
  width: calc(100% - 80px);
  justify-content: space-between;
  border: 2px solid transparent;
  background: white;
  padding: 30px;
}
.arro-take-5 {
  content: "";
  transform: rotate(90deg);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  /* margin-left: 5%; */
}
.para-9 {
  color: black;
  line-height: 1.5;
  font-size: 17px;
  cursor: pointer;
}

.btn-take-reply {
  border-radius: 2px;
  background-color: #f65000;

  padding: 7px 19px;
  transform: skewX(-10deg) scaleX(0.955);
  border: none;
  color: white;
  cursor: pointer;
}
.btn-take-reply:hover {
  background-color: #dd4800;
}
.btn-take-reply1:hover {
  background-color: #dd4800;
}
.span-7-take {
  font-size: 16px;
  display: flex;
  gap: 6px;
  align-items: center;
}
/* com2 */
.unknown-p-img3 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  margin-right: 20px;
}
.take-h15 {
  color: black;
  font-style: normal;
  clear: both;
  display: block;
  font-weight: 100;
  font-size: 24px;
  /* line-height: 1; */
}
.comment-date2 {
  color: #464646;
  line-height: 1.5;
  font-size: 15px;
  cursor: pointer;
  font-weight: 100;
}
.comment-2-take {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.comment-sec-take2 {
  display: flex;

  /* width: calc(99% - 61px); */
  border: 2px solid transparent;
  background: white;
  padding: 30px;
}
.arro-take-6 {
  content: "";
  transform: rotate(90deg);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  /* margin-left: 5%; */
}
.para-10 {
  color: black;
  line-height: 1.5;
  font-size: 17px;
  cursor: pointer;
}

.btn-take-reply1 {
  border-radius: 2px;
  background-color: #f65000;
  padding: 7px 19px;
  cursor: pointer;
  transform: skewX(-10deg) scaleX(0.955);
  border: none;
  color: white;
}
.span-8-take {
  font-size: 16px;
  display: flex;
  gap: 6px;
  align-items: center;
}
.comm-sec-h {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.take-h16 {
  font-size: 40px;
  font-family: "Odibee Sans", cursive;
  font-style: italic;
  display: flex;
  text-transform: uppercase;
  margin: 10px 0px;
}
.para-11 {
  color: #464646;
  line-height: 1.5;
  font-size: 17px;
}
.star-sta {
  color: #dd4800;
}
.inputs-take {
  padding: 0.93em 1.2em;
  width: 100%;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  /* -webkit-appearance: none; */
  box-sizing: border-box;
  font-weight: 400;
  border-radius: 5px;
  outline: 0;
  transition: all ease-in-out 0.3s;
}
.for-o-take {
  display: flex;
  margin: 10px 0px;
  justify-content: space-around;
}
.text-aa-take {
  padding: 0.93em 1.25em;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  font-size: 15px;
  height: 15vh;
  border-color: white;
  overflow: auto;
  vertical-align: top;
  /* -webkit-appearance: none; */
  box-sizing: border-box;
  font-weight: 400;
  border-radius: 5px;
  outline: 0;
  transition: all ease-in-out 0.3s;
}
.take-form {
  display: flex;
  justify-content: center;
}
.para-12 {
  color: #464646;
  line-height: 1.5;
  font-size: 17px;
}
.check-take {
  display: flex;
  gap: 1rem;
  margin: 10px 0px;
}
.post-btn-take {
  color: black;
  padding: 10px 16px;
  transform: skewX(-10deg) scaleX(0.955);
  border: transparent;
  border-radius: 3px;
  background-color: white;
}

.post-btn-take:hover {
  background-color: #dd4800;
  color: white;
}
.post-ty8 {
  display: flex;
  justify-content: center;
}


.take-lh1 {
  font-size: 30px;
  color: white;
  font-family: "Odibee Sans", cursive;
  font-style: italic;
  display: flex;
  text-transform: uppercase;
}
.take-fl-l {
  display: flex;
  align-items: center;
  gap: 10px;
}
.svg-takel {
  font-size: 30px;
  color: #dd4800;
}
.hr-1-l {
  margin: 15px 0px;
  border: 2px solid #dd4800;
  transform: skewX(-20deg);
}
.typ-ser {
  line-height: 1;
  padding: 12px 25px;
  padding-right: 55px;
  padding-left: 0;
  flex: 1;
  color: white;
  border-radius: 0;
  border-width: 0 0 1px;
  /* padding: 0.93em 1.25em; */
  background-color: var(--background);
  /* border-width: 1px; */
  border-style: solid;
  border-color: white;
  /* -webkit-appearance: none; */
  box-sizing: border-box;
  font-weight: 400;
  /* border-radius: 5px; */
  outline: 0;
  transition: all ease-in-out 0.3s;
}
.typ-ser:focus {
  border-color: #dd4800;
}
.search-f-h {
  margin-top: 37px;

  display: flex;
  align-items: flex-start;
}
.search-take-b {
  /* transform: skewX(-10deg); */
  padding: 5px 12px;
  transform: translateX(-83px);
  border: none;
  border-radius: 3px;
  background-color: white;
  color: black;
}
.search-take-b:hover {
  background-color: #dd4800;
  color: white;
}
.take-lh2 {
  font-size: 26px;
  color: white;
  font-family: "Odibee Sans", cursive;
  font-style: italic;
  display: flex;
  text-transform: uppercase;
}
.take-fl-2 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0px;
}
.spa-5-t {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: -4px 10px 0;
  vertical-align: middle;
  background-color: #ccc;
  transform: skewX(-15deg);
}
.hr-io {
  margin: 10px 0px;
  width: 80%;
  border: 1px solid #464646;
}

.col-1-t {
  margin: 15px 0px;
  display: flex;
  align-items: center;
}
.take-th-1 {
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 100;
}
.take-th-1:hover {
  color: #DD4800;
}

.top-fgi {
  margin-top: 25px;
}

.col-1-t:hover span {
  background-color: #dd4800;
}

.take-lh3 {
  color: white;
  font-size: 30px;
  font-family: "Odibee Sans", cursive;
  font-style: italic;
  display: flex;
  text-transform: uppercase;
}
.take-fl-3 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0px;
}
.latest-post-t {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.update-latest {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.para-latest1 {
  color: #b3b3b3;
  padding: 5px 0px;
  cursor: pointer;
  font-size: 15px;
}
.para-latest1:hover {
  color: #dd4800;
}

.latest-img-up{

  display: block;
  width: auto;
  height: auto;
}


.gat-gg{

  display: flex;
  gap: 1rem;
  flex-direction: column;
  
}

.take-lh4{
  color: white;
  font-size: 30px;
  font-family: "Odibee Sans", cursive;
  font-style: italic;
  display: flex;
  text-transform: uppercase;
}
.take-fl-4{
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0px;
}
.btn-side-bar-take{
  transform: skewX(-10deg) scaleX(.955);
  padding: 5px 17px;
  border: none;
  border-radius: 3px;
  background-color: white;
  color: black;
}

.btn-side-bar-take:hover{
  background-color: #dd4800;
color: white;

}
.btn-ttr{

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media only screen and (max-width: 830px) {

  .left-h-t {
  
   display: none;

  }


}

