.LatestProductCarousel{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 120px;
    border-bottom: 1px solid grey;
    margin-left: 20px;
    margin-right: 20px;
}

.ShopProduct{
    display: flex;
    overflow-y: scroll;
    gap: 10px;
    cursor: pointer;
}


.ShopProductCard{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.ButtonShopCardLabel{
    color: rgb(0, 0, 0);
    position: absolute;
    z-index: 9;
    font-weight: bold;
    margin-top: 7px;
    margin-left: 13px;
    cursor: pointer;
}

.ButtonShopCardButton{
    width: 105px;
    background-color: white;
    transform: skewX(-15deg);
    height: 35px;
    cursor: pointer;
}


.ButtonShopCard:hover .ButtonShopCardButton{
    width: 105px;
    background-color: rgb(0, 0, 0);
    transform: skewX(-15deg);
    height: 33px;
    border: 2px solid white;
    transition: 0.4s;

}


.ButtonShopCard:hover .ButtonShopCardLabel{
    color: rgb(255, 255, 255);
    position: absolute;
    z-index: 1;
    font-weight: bold;
    transition: 0.4s;

 
}


.ButtonShopCardActive .ButtonShopCardButton{
    width: 105px;
    background-color: rgb(0, 0, 0);
    transform: skewX(-15deg);
    height: 33px;
    border: 2px solid white;

}

.ButtonShopCardActive .ButtonShopCardLabel{
    color: rgb(255, 255, 255);
    position: absolute;
    z-index: 8;
    font-weight: bold;
 
}


.ButtonBarShop{
    width: 85%;
display: flex;
justify-content: space-between;
}

@media (max-width:835px) {
    .ButtonBarShop{
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ShopByTitle {
        margin-top: 50px;
        justify-content: center;
        display: flex;
        align-items: center;
        /* position: relative; */
        margin-bottom: 20px;
        -webkit-user-select: none;
        user-select: none;
    }

    .ButtonBarShopButton {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 15px;
    }
}

.ButtonBarShopButton{
    display: flex;
    align-items: center;
    gap: 5px;

}


@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@800&display=swap');


.ShopByTitle{
    margin-top: 50px;
   justify-content: center;
    display: flex;
    align-items: center;
    /* position: relative; */
    margin-bottom: 50px;
    user-select: none;
}

 .ShopBySpan{
    color: white;
    font-weight: 800;
    font-size: 25px;
    margin-right: 5px;
    transform: skew(-15deg);
    font-family: 'Plus Jakarta Sans', sans-serif;
 }


 .ShopProductCardOnClickNO{
    display: none;
 }

.ShopProductCardOnClick{
    display: flex;
    justify-content: center;
    width: 85%;
}

@media (max-width:700px) {
    .ShopProductCardOnClick{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 85%;
    }

    .CategoryCardImage{
       background-position: center;
    }
    .flex-rr{
        flex-direction: column !important; 
    }
}

.flex-rr{
    flex-direction: row-reverse;
}