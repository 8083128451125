
* {
    margin: 0;
    padding: 0;

}
.main {
    display: flex;
    /* height: 80vh; */
    width: 100%;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
  }
  

  
  .accordion {
    margin: 30px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  
  .accordion__item {
    width: 100% ;
    padding-bottom: 30px !important;
    max-height: 25px;
    padding: 17px 10px;
    border-bottom: 1px solid #c9c9c9;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
  }
  
  .accordion__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Lato", Arial, sans-serif;
  }
  
  .accordion__header h4 {
    transition: 0.2s ease-in-out;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #f65000;
  }
  
  .accordion__header i {
    transition: 0.2s ease-in-out;
    transform-origin: center;
    margin-bottom: 10px;
  }
  

  
  .accordion__header:hover i {
    color: #f65000;
  }
  
  .accordion__content {
    margin: 16px 0px;
    font-family: "Lato", Arial, sans-serif;
  }

  