.ReviewCard{
    cursor: pointer;
    user-select: none;
    min-height: 350px;
    min-width: 400px;
    padding: 50px 50px 50px 50px;
    border-right: 1px solid lightslategray;
    margin-top: 50px;
}
.ReviewCardStar{
    margin-bottom: 5px;
}


.ReviewCardSpan1{
 font-size: 19px;
    font-weight: 600;
    color: white;
}


.ReviewCardSpan2{
    font-size: 13px;
    font-weight: 600;
    color: white;

}

.ReviewCardP{
    margin-top: 20px;
    font-style: italic;
    color: lightslategray;
}