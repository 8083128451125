.ForumPost{
    width: 100%;
    background-color: white;
    margin-top: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

    align-items: center;
}

.ForumPostHeader{
    width: 95%;
    display: flex;
    gap: 10px;
    margin-top: 20px;
}


.ForumPostHeaderLeftImg{
    width: 60px;
    height: 60px;
    border-radius: 50px;
}

.ForumPostHeaderRight{
    padding-top: 10px;
}


.ForumPostHeaderRighth5{
    font-family: 'Mukta', sans-serif;
    font-weight: bold;
}

.ForumPostHeaderRighth6{
    font-family: 'Mukta', sans-serif;
    color: rgb(128, 128, 128);
}

.ForumPostBody{
    margin-top: 10px;
    width: 95%;
    background-color: rgba(211, 211, 211, 0.589);
    padding: 20px 50px;
    border-radius: 15px;
}

.ForumPostBody p{
    font-family: 'Mukta', sans-serif;
    line-height: 23px;
}

.ForumPostFotter{
    width: 95%;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.ForumPostFotterButton{
    padding: 10px 18px;
    border-radius: 15px;
    border: none;
    background-color: #f65000;
    color: white;
    margin-top: 10px;
    cursor: pointer;
}


.ForumPostFotterButton:hover{
    padding: 10px 18px;

    border-radius: 15px;
    border: none;
    background-color: #f65000;
    color: white;
    margin-top: 10px;
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.4s;
}