.accordion1 {
    color: white;
    /* display: flex;
      justify-content: center;
      padding-bottom: 100px; */
  }
  
  .bg-f {
    background-image: url("./Faq_bg.jpg");
    /* background-position: center center; */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 15px;
  }
  .Faq-h1 {
      color: #f65000;
      font-size: 15px;
      line-height: 2;
      font-weight: 600;
      /* transform: translateY(10px); */
      text-transform: uppercase;
      font-family: "Lato", Arial, sans-serif;
  }
  .faq-h2{
      font-size: 45px;
      font-family: "Odibee Sans", cursive;
  }
  .faq-p1{
      font-family: "Lato", Arial, sans-serif;
      color: #464646;
      margin-top: 15px;
      line-height: 2;
      font-size: 14px;
  }
  .faq-p2{
      font-family: "Lato", Arial, sans-serif;
      color: #464646;
      margin-top: 10px;
     
      font-size: 20px;
  }
  
  .faq-h{
  
      display: flex;
      /* padding: 74px; */
      min-height: 500px;
      align-items: center;
      justify-content: center;
      /* gap: 2rem; */
  }
  .faq-sup-img{
  
      width: 60%;
      display: flex;
      transform: translateY(95px);
      /* margin-top: 174px; */
      justify-content: center;
  }
  .faq-text-hh{
      width: 34%;
  }
  
  .img-faq{
  
      width: 500px;
  }
  
  @media only screen and (max-width: 970px) {
  
  .bg-f {
      background-image: url("./Faq_bg.jpg");
  
      /* background-position: center center; */
      background-position-x: right;
  
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
  }
  .img-faq {
      width: 350px;
  }
  .faq-text-hh {
      width: 45%;
  }
  .faq-sup-img{
  
      width: 40%;
  }
  .faq-h {
  
  gap: 2rem;
  
  }
  }
  
  @media only screen and (max-width:730px) {
  
      .faq-sup-img {
  display: none;
      }
  
      .faq-text-hh {
          width: 90%;
          text-align: center;
      }
  
  }
  .acco-r-t {
      color: white;
      display: flex;
      justify-content: center;
  }
  .faq-h3{
      font-size: 35px;
      font-family: "Odibee Sans", cursive;
      font-style: italic;
      display: flex;
  
  }
  .main-faq-m{
      width: 50%;
  }
  .gen-fd{
  
      width: 30%;
      margin: 28px 5px;
  }
  
  @media only screen and (max-width: 760px) {
  
  
      .acco-r-t {
          color: white;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
      }
      .gen-fd {
          width: 80%;
          margin: 28px 5px;
          text-align: center;
      }
      .main-faq-m {
          width: 80%;
      }
      }
      @media only screen and (max-width: 500px) {
  
  
      .main {
          display: flex;
          /* height: 80vh; */
          width: 100%;
          align-items: center;
          flex-direction: column;
          row-gap: 20px;
      }
      .main-faq-m {
          width: 100%;
      }
      .accordion__item {
          width: 100%;
      }
  }
  .arrow-spa{
      color: #f65000;
  }


  .FAQS{
    margin-left: 10px;
    margin-right: 10px;
  }