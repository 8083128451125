.carousel.carousel-slider {
    position: relative;
    margin: 0;
    border-radius: 15px;
    overflow: hidden;
  }
  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 0px solid #333;
  }
  .carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 0px solid #fff;
    padding: 0px;
  }

  .carousel .control-dots {
    /* bottom: 0; */
    display: none !important;
  }
  .carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
    display: none !important;
  }
  .carousel .carousel-status {
    display: none !important;
  }
  .carousel .thumbs-wrapper {
    margin: 20px 0px !important;
    overflow: hidden;
  }
  .main-carousel {
    width: 45%;
  }
  
  .carousel-product {
    display: flex;
    /* width: 90%; */
    justify-content: center;
    gap: 29px;
    flex-wrap: wrap;
  }
  .carousel .thumbs {
    transition: all 0.15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-data1 {
    width: 45%;
    color: white;
    font-family: "Lato", Arial, sans-serif;
  }
  .carou-text {
    font-family: "Odibee Sans", cursive;
    font-size: 44px;
    color: white;
    font-weight: 100;
    font-style: italic;
    margin-top: 10px;
  }
  .carousel-product {
    display: flex;
  
    justify-content: center;
    /* gap: 10px; */
    flex-wrap: wrap;
  }
  .carousel .thumbs {
    transition: all 0.15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  .in-s {
    font-family: "Lato", Arial, sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.4;
    padding: 2px 8px;
    text-transform: uppercase;
    position: relative;
    background-color: transparent;
    z-index: 1;
    color: #39b54a;
    transform: skewX(10deg);
  }
  .ske-55 {
    background: #e3ffed;
    width: fit-content;
    display: flex;
    transform: skewX(-10deg) scaleX(0.955);
  }
  .review-t {
    font-size: 15px;
    font-weight: 100;
    color: #999;
    cursor: pointer;
  }
  .review-t:hover {
    color: #f65000;
  }
  .star-svg1 {
    color: #f65000;
  }
  .star-col {
    display: flex;
    margin-top: 10px;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
  }
  .star-div7 {
    display: flex;
  }
  .bran-text {
    font-size: 15px;
    font-weight: 100;
    color: #999;
  }
  .bran-text2 {
    font-size: 15px;
    font-weight: 100;
    color: white;
  }
  .bran-text2:hover {
    color: #f65000;
    cursor: pointer;
  }
  .brand-texts {
    display: flex;
  }
  
  .text-actualprice {
    font-size: 18px;
    font-weight: 100;
    color: #999;
    text-decoration: line-through;
  }
  .text-discountp {
    font-size: 25px;
    font-weight: 100;
    color: #f65000;
  }
  .price-p4 {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 2;
  }
  .p-para-cartt {
    line-height: 1.5;
    color: #999;
  }
  .sold-text {
    display: flex;
    line-height: 2;
    gap: 3px;
  }
  .sold-t {
    font-size: 18px;
    font-weight: 100;
    color: #999;
  }
  .solid-t1 {
    font-size: 18px;
    font-weight: 100;
  }
  .deal-progress .progress-bar {
    width: 42%;
    height: 3px;
    z-index: 2;
    position: absolute;
    overflow: hidden;
    background-color: #ccc;
  }
  .deal-progress {
    width: 100%;
  }
  .progress-bar {
    border-radius: 5px;
    height: 5px;
  }
  .progress-value {
    width: 17%;
    position: absolute;
    height: 3px;
    z-index: 5;
    background-color: #f65000;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 15px;
  }
  .ava-text {
    font-size: 15px;
    font-weight: 100;
    color: #999;
  }
  .ava-div {
    line-height: 5;
  }
  .incdec-butt {
    display: flex;
    gap: 3rem;
    border-bottom: 2px solid;
    line-height: 2;
    align-items: center;
  }
  .btn-count {
    border: none;
    background: transparent;
    color: white;
    font-size: 25px;
  }
  .count-h {
    font-size: 25px;
    font-weight: 100;
  }
  
  .progress {
    background: rgba(255, 255, 255, 0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    /* padding: 0 5px; */
    display: flex;
    height: 8px;
    width: 100%;
  }
  
  .progress-value {
    animation: load 3s normal forwards;
    /* box-shadow: 0 10px 40px -10px #f65000; */
    border-radius: 100px;
    background: #f65000;
    height: 8px;
    width: 0;
  }
  
  @keyframes load {
    0% {
      width: 0;
    }
    100% {
      width: 68%;
    }
  }
  
  /* Container Tooltip */
  .tooltip2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: dimgray;
    cursor: pointer;
  }
  
  /* Style text tooltip */
  .tooltipText2 {
    background-color:white;
    position: absolute;
    bottom: 130%;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0;
    white-space: pre;
    transition: all 0.5s;
  }
  
  .fb1 {
    color: black;
  }
  
  /*  Membuat dan style tip*/
  .tooltipText2::after {
    content: "";
    border-width: 5px;
    border-style: solid;
    border-color:white transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 40%;
    margin-left: 5%;
  }
  
  /* Style Icon */
  
  /* Hover text tooltip */
  .tooltip2:hover .tooltipText2 {
    opacity: 1;
    transform: translateY(-10px);
  }
  .tooltip-svgs1 {
    display: flex;
    gap: 10px;
  }
  .font-svg1 {
    font-size: 25px;
  }
  .comtt {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
  .cat-text {
    font-size: 15px;
    font-weight: 100;
    color: #999;
  }
  .cat-text1 {
    font-size: 15px;
    font-weight: 100;
    color: white;
  }
  .cat-text1:hover {
    color: #f65000;
    cursor: pointer;
  }
  .text-fg {
    display: flex;
    flex-direction: column;
    line-height: 2;
    margin-top: 20px;
  }
  .floo {
    display: flex;
    gap: 2px;
  }
  
  .share-tt {
    font-size: 15px;
    font-weight: 100;
    color: #999;
  }
  .social-i-cart {
    display: flex;
    gap: 5px;
    margin-top: 5px;
  }
  .social-iconsisv {
    display: flex;
    gap: 10px;
  
    font-size: 18px;
  }
  .social-iconsisv > svg {
    cursor: pointer;
  }
  .tw-v {
    color: #0055ff;
  }
  .fb-v {
    color: #3889f2;
  }
  
  .linkk-v {
    color: #0a66c2;
  }
  
  .insta-v {
    color: #f703b6b4;
  }
  
  .carousel .thumb {
    border-radius: 15px;
  }
  @media only screen and (max-width: 730px) {
  
      .carousel-product {
          display: flex;
          justify-content: center;
          /* gap: 10px; */
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
      }
  
      .main-carousel {
          width: 80%;
      }
      .product-data1 {
          width: 80%;
          color: white;
          font-family: "Lato", Arial, sans-serif;
      }
  }
  .carousel .slide img {
      width: 100%;
      vertical-align: top;
    
      border-radius: 15px !important;
  }
  .Pro-texts{
      font-family: "Lato", Arial, sans-serif;
      display: flex;
      margin: 40px;
      color: white;
      align-items: center;
      gap: 1px;
      flex-wrap: wrap;
  }
  .h-text{
      color: #999;
      font-size: 15px;
      font-weight: 100;
     cursor: pointer;
  }
  .h-text:hover{
      color: #f65000;
  }
  .apsp-text:hover{
      color: #f65000;
  
  }
  .apsp-text{
      font-size: 15px;
      font-weight: 100;
      color: #999;
      cursor: pointer;
   
  }
  .nat-text{
      font-size: 15px;
      font-weight: 100;
  }
  .arrow-span{
  display: flex;
  }
  