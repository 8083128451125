
.ProductHorizontalCard{
    width: 380px;
    height: 150px;
    background-color: white;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
  }


  .ProductHorizontalCardLeft{
    width: 40%;
  

  }

  .ProductHorizontalCardRight{
    width: 60%;
    padding-left: 15px;
    padding-top: 20px;
  }


  .ProductHorizontalCardLeftimg{
    height: 150px;
    background-color: rgba(211, 211, 211, 0.452);
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .ProductHorizontalCardRightSpan{
  font-weight: 600;
  font-size: 15px;

  }


  .ProductHorizontalCardRightStar{
    margin-top: 5px;
    display: flex;
    margin-bottom: 10px;
  }


  .ProductHorizontalCardRightSpan1{
    font-size: 18px;
    
  }


  .ProductHorizontalCard:hover   .ProductHorizontalCardRightSpan{
    font-weight: 600;
    font-size: 15px;
     color: #F65000;
     transition: 0.3s;
    }
