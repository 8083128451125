
/* @import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap");


.font4{
    font-family: 'Staatliches', cursive;
}
.Head-goal{
    width: 70%;
    display: flex;
    justify-content: center;
}
.head-1{
display: flex;
justify-content: center;
align-items: center;
}

.heading-c{
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 40px;
}
.heading-c>svg{

    color: #f65000;
    font-size: 25px;
}
.img-goal>img{
    width: 300px;
}
.Goal-img-texts{
    position: absolute;
    text-align: center;
    z-index: 5;

}
.Goal-img-texts1{
    position: absolute;
    text-align: center;
    z-index: 5;
    /* transform: translate(40px, 80px); */
}
.Goal-h1{
    color: white;
    font-style: italic;
    font-family: "Odibee Sans", cursive;
}
.Goal-h2{
font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: white;
    font-weight: 400;
    text-decoration: none;
}

.img-goal:hover{
    transition: 0.5s;
}
.head-gg{
display: flex;
    align-items: center;
    justify-content: center;
}
.head-gg1{
    display: flex;
        align-items: center;
        justify-content: center;
    }
.head-gg:hover img,
.img-goal.hover img {
    transform: scale(1.1);
    filter: brightness(0.3);
    transition: 0.5s;
}
@media only screen and (max-width: 1250px) {
    .Head-goal {
        width: 70%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

}
}
.Head-goal1{
    width: 70%;
    display: flex;
    justify-content: center;
}
.head-11{
display: flex;
justify-content: center;
align-items: center;
}
.heading-c1>h1{
    align-items: center;
    font-style: italic;

}
.heading-c1{
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 40px;
}
.heading-c1>svg{

    color: #f65000;
    font-size: 25px;
}
.img-goal1>img{
    width: 300px;
}
.Goal-img-texts1{
    position: absolute;
    text-align: center;
    z-index: 5;
    /* transform: translate(71px, 80px); */
}
.Goal-img-texts11{
    position: absolute;
    text-align: center;
    z-index: 5;

}

.Goal-h21{
font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: white;
    font-weight: 400;

}


@media only screen and (max-width: 1250px) {
    .Head-goal1 {
        width: 70%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

}
}
@media only screen and (max-width: 600px) {

    .Head-goal1 {
        display: flex !important;
        }
        .Head-goal {
            display: none;
            }
}
.Head-goal1 {
display: none;
}

.colo-g{
background-color: #f65000;
}
.colo-f{
background-color: red;
}
.Goal-h11 {
    color: white;
    font-family: "Odibee Sans", cursive;
    font-style: italic;
}
.heading-c>h1 {
    align-items: center;
    font-style: italic;
    color: white;
    font-family: "Zuume";

}


.img-goal-img1{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}


.img-goal-img4{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}


@media only screen and (max-width: 1250px) {
    .img-goal-img1{
        border-top-left-radius: 15px ;
        border-bottom-left-radius: 0px !important;
    }

    .img-goal-img2{
        border-top-right-radius: 15px !important;
    }
    
    .img-goal-img3{
    
        border-bottom-left-radius: 15px !important;
    }
    
    .img-goal-img4{
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 15px;
    }
}


@media only screen and (max-width: 860px) {
    .img-goal-img1{
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
    }

    .img-goal-img2{
        border-top-right-radius: 0px !important;
    }
    
    .img-goal-img3{
    
        border-bottom-left-radius: 0px !important;
    }
    
    .img-goal-img4{
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px;
    }
}


.img-goal-img12{
    border-bottom-left-radius: 15px ;
    border-bottom-right-radius: 15px;
}


.img-goal-img11{
    border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
}