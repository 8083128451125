.ForumName{
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    align-items: center;

}

.ForumNameBody{
    width: 80%;
    display: flex;
    flex-direction: column;
}

.ForumNameBodyCommentBox{
    width: 100%;
    height: 220px;
    background-color: white;
    margin-top: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
}

.ForumNameBodyCommentBoxHeader{
    width: 95%;
    display: flex;
    flex-direction: column;

}

.ForumNameBodyCommentBoxBody{
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
}

.ForumNameBodyCommentBoxBodyButton{
    padding: 10px 20px;
    background-color: #f65000;
    border: none;
    border-radius: 15px;
    color: white;
    margin-bottom: 10px;
    cursor: pointer;
}


.ForumNameBodyCommentBoxBodyButton:hover{
    padding: 10px 20px;
    background-color: #f65000;
    border: none;
    border-radius: 15px;
    color: white;
    margin-bottom: 10px;
    transform: scale(1.02);
    transition: 0.4s;
}