.VlogPlaylist{
    width: 100%;
    height: 500px;

    display: flex;
    justify-content: center;
}


@media (max-width:900px) {
    .VlogPlayListBody{
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
    } 

    .VlogPlayListBodyLeft {
        width: 100% !important;
        height: 100%;
    }

    .VlogPlayListBodyLeftdiv2 {
        width: 100% !important;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 15;
        position: absolute;
    }

    .VlogPlayListBodyLeftdiv3 {
        width: 100% !important;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        z-index: 10;
        flex-direction: column;
    }

    .VlogPlayListBodyRight {
        display: none;
        overflow-y: scroll;
        width: 30%;
        height: 100%;
        z-index: 2500;
    }

    .VlogCardTitleDivH1 {
        color: white;
        font-weight: 500;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 18px !important;
        font-family: 'Roboto', sans-serif;
        padding-bottom: 10px !important;
    }
}


@media (max-width: 500px) {
    .VlogCardTitleDivH1 {
        color: white;
        font-weight: 500;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 18px !important;
        font-family: 'Roboto', sans-serif;
        padding-bottom: 10px !important;
    }

    .VlogPlayListBody{
        width: 95% !important;
        height: 100%;
        display: flex;
        flex-direction: column;
    } 

    .VlogPlayListBodyLeftdiv {
        position: absolute;
        width: 100% !important;
        height: 500px;
        display: flex;
        flex-direction: column;
    }
}


.VlogPlayListBody{
    width: 70%;
    height: 100%;
    display: flex;
}


.VlogPlayListBodyLeft{
    width: 70%;
    height: 100%;
   
}


.VlogPlayListBodyRight{
    overflow-y: scroll;
    width: 30%;
    height: 100%;
    z-index: 2500;
}


.VlogPlayListBodyRight::-webkit-scrollbar {
    width: 5px;

  }
  
  /* Track */
  .VlogPlayListBodyRight::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .VlogPlayListBodyRight::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .VlogPlayListBodyRight::-webkit-scrollbar-thumb:hover {
    background: #f65000; 
  }


.VlogPlayListBodyLeftImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-repeat: no-repeat;
}


.VlogPlayListBodyLeftdivButton{
    padding: 15px;
    height: fit-content;
    color: white;
    border-radius: 150px;
    background-color: #f65000;
    border: none;
cursor: pointer;
}


.VlogPlayListBodyLeftdivButton:hover{
    padding: 15px;
    height: fit-content;
    color: white;
    border-radius: 150px;
    background-color: #f65000;
    border: none;
    transform: scale(1.1);
    transition: 0.4s;
}


.VlogPlayListBodyLeftdiv{
    position: absolute;
    width: 70%;
    height: 500px;
    display: flex;
    flex-direction: column;
}

.VlogPlayListBodyLeftdiv2{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    position: absolute;
}


.VlogPlayListBodyLeftdiv3{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 10;
    flex-direction: column;
}


