* {
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
.con-text {
  margin-bottom: 20px;
text-align: center;
padding: 10px 0px;
  color: white;
  font-family: 'Zen Dots', cursive;

}


.card-rr1 {
  background-color: white;
  width: 300px;
  display: flex;
  border-top-left-radius: 35px;
  border-bottom-right-radius: 35px;
  align-items: center;
  justify-content: center;
}
.card-rr2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 15px 10px;
}
.icon-mc>svg{

}
.add-c1>span{
font-family: 'Zen Dots', cursive;

    font-size: 19px;    
}
.add-c2>span{
    font-size: 16px;    
  font-family: 'Roboto Condensed', sans-serif;

}
.ico-mc2{
    border: 1px solid;
    padding: 10px;
    background: black;
    color: white;
    border-radius: 33px;
    font-size: 60px;
}
.row-card-con{

    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    justify-content: center;

}
.add-c2{

text-align: center;
}