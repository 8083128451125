.VlogCard{
    height: 500px;
    width: 1000px;
    margin-top: 80px;
    border-radius: 15px;
    box-shadow: rgba(214, 80, 3, 0.3) 0px 0px 0px 3px;
    position: absolute;
}
.CardVlog{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 500px;
    margin-bottom: 100px;
}


.VlogCardImg{
    width: 1000px;
    height: 100%;
    border-radius: 15px;
    background-position: center;
    object-fit: cover;
}

.VlogCardButton{
    padding: 10px;
    background-color: #f65000;
    border-radius: 150px;
    color: white;
    outline: none;
    border: none;
cursor: pointer;
}


.VlogCardButton:hover{
    padding: 10px;
    background-color: #f65000;
    border-radius: 150px;
    color: white;
    transform: scale(1.2);
    transition: 0.7s;
}

.VlogCardButtonDiv{
    height: 500px;
    width: 1000px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 15;
}

.VlogCardTitleDiv{
    height: 500px;
    width: 1000px;
    margin-top: 80px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    flex-direction: column;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.VlogCardTitleDivH1{
    color: white;
    font-weight: 500;
    padding-left: 5%;
    padding-right: 5%;

    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 60px;
  
}


.VlogCardTitleDivP{
    color: white;
    font-weight: 500;
    padding-left: 5%;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 60px;
    text-align: center; 

}

.VlogCradbg{
    background-color: #f65000;
    width: max-content;
    padding: 5px 10px;
    text-align: center;
    margin-left: 5%;
    margin-bottom: 15px;
    height: 25px;
}



@media (max-width:1000px) {
    .VlogCard{
        height: 500px;
        width: 80%;
        margin-top: 80px;
        border-radius: 15px;
        box-shadow: rgba(214, 80, 3, 0.3) 0px 0px 0px 3px;
        position: absolute;
    }
    .CardVlog{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 500px;
        margin-bottom: 100px;
    }
    
    
    .VlogCardImg{
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background-position: center;
        object-fit: cover;
    }
    
    .VlogCardButton{
        padding: 10px;
        background-color: #f65000;
        border-radius: 150px;
        color: white;
        outline: none;
        border: none;
    cursor: pointer;
    }
    
    
    .VlogCardButton:hover{
        padding: 10px;
        background-color: #f65000;
        border-radius: 150px;
        color: white;
        transform: scale(1.2);
        transition: 0.7s;
    }
    
    .VlogCardButtonDiv{
        height: 500px;
        width: 80%;
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 15;
    }
    
    .VlogCardTitleDiv{
        height: 500px;
        width: 80%;
        margin-top: 80px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        flex-direction: column;
    }
    
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
    
    .VlogCardTitleDivH1{
        color: white;
        font-weight: 500;
        padding-left: 5%;
        padding-right: 5%;
    
        font-size: 24px;
        font-family: 'Roboto', sans-serif;
        padding-bottom: 60px;
      
    }
    
    
    .VlogCardTitleDivP{
        color: white;
        font-weight: 500;
        padding-left: 5%;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        padding-bottom: 60px;
        text-align: center; 
    
    }
    
    .VlogCradbg{
        background-color: #f65000;
        width: max-content;
        padding: 5px 10px;
        text-align: center;
        margin-left: 5%;
        margin-bottom: 15px;
        height: 25px;
    }
    
}