.Vlog{
    width: 100%;
    display: flex;
    justify-content: center;
flex-direction: column;
}


.VlogPlaylistt{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-direction: column;
}

.VlogPlaylistt h1{
color: white;
}


.VlogPlaylisttHeader{
    width: 100%;
    display: flex;
    justify-content: center;

}

.VlogPlaylisttHeader1{
width: 70%;
margin-bottom: 15px;
}


.VlogCardListh1{
    margin-top: 25px;
    color: white;
text-align: center;
font-family: "Roboto", sans-serif;
}

.VlogCardListLine{
    width: 100%;
margin-top: 10px;
    display: flex;
    justify-content: center;
}

.VlogCardListLine2{
width: 200px;
height: 2px;
background-color: white;
display: flex;
justify-content: center;
}

.VlogCardListLine3{

    width: 100px;
    height: 2px;
    background-color: #f65000;
    }


    .TrendingFlex{
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }