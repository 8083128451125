@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: #000000;
  font-family: "Montserrat", sans-serif;
}

.Navbar{
  margin-top: 10px;
  height: 75px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.NavbarMenu{
  display: flex;
  align-items: center;
  gap: 25px;
  height: 75px;
}

.NavBarMenuLogo{
  height: 100px;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
.NavBarMenuNavlink{
  text-decoration: none;
  font-size: 21px;
  font-weight: 400;
  font-style: italic;
  color: black;
  font-family: 'Anton', sans-serif;
  
}

.NavBarMenuNavLinkDiv{
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavBarMenuNavLinkDiv:hover{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F65000;
}

.NavBarMenuNavLinkDiv{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: black;
  gap: 6px;
  font-family: 'Anton', sans-serif;

}

.NavBarMenuNavLinkSpan{
  width: 7px;
  height: 7px;
  transform: skewX(-10deg);
  display: inline-block;
  background-color: black;
}



.NavBarMenuNavLinkDiv:hover .NavBarMenuNavLinkSpan{
  background-color: #F65000;
}

.NavBarMenuNavlink-active{
  text-decoration: none;
}

.NavBarMenuNavlink-active .NavBarMenuNavLinkSpan{
  background-color: #F65000;
}

.NavbarMenu2{
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}


.NavbarMenu2Input{
  padding: 10px 20px 10px 20px;
 border: 1px solid rgba(0, 0, 0, 0.281);
 transform: skewX(-15deg);
 -webkit-transition: 0.5s;
 transition: 0.5s;
 outline: none;
}

.NavbarMenu2Input:hover {
 
  border: 1px solid #F65000;
 }

.NavbarMenu2Input:focus {
 
 border: 1px solid #F65000;
}

.NavbarMenu2Button{
  background-color: white;
  color: grey;
  border: none;
  position: absolute;
  margin-top: 8px;
  margin-left: -29px;
}

.NavbarMenu2Component1:hover .NavbarMenu2Button{
  color: black;
  transition: 0.5s;
}


.NavbarMenu2Component2Icon{
  color: black;
}

.NavbarMenu2Component2Span{
  color: rgba(0, 0, 0, 0.514);
  font-weight: 600;
}

.NavbarMenu2Component2NavLink{
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 3px;
}


.NavbarMenu2Component2NavLink:hover .NavbarMenu2Component2Icon{
  color:#F65000;
  transition: 0.4s;
}

.NavbarMenu2Component2NavLink:hover .NavbarMenu2Component2Span{
  color:#F65000;
  transition: 0.4s;

}


.NavbarMenu2Component3{
  display: flex;
  gap: 3px;
}

.NavbarMenu2Component3Count{
  position: absolute;
  min-width: 14px;
  padding: 2.5px 0;
  line-height: 1;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-left: 12px;
  letter-spacing: 0;
  border-radius: 50%;
  color: #fff;
  background-color: #F65000;
}




.nav-uss-dr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}






/* .dropdown {
  float: left;
  overflow: hidden;
} */

/* .dropdown .dropbtn {
  font-size: 16px;  
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
} */

.homePagePRofileImage{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #F65000;

}

.Loggedinuserhomepage{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

/* .navbar a:hover, .dropdown:hover .dropbtn {
  background-color: red;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
} */

/* .dropdown-content a {

  font-style: italic;
  font-size: 20px;
  font-family: 'Anton', sans-serif;

  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
} */

/* .dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
} */


.close-icon-nav{
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.close-icon-nav>svg{

cursor: pointer;
}



.nav-mb-link{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
}
.nav-mb-link>a{
  color: #000000;


}
.side-nvv-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width:890px) {

  .nav-op-btn{

    display: unset !important;
  }
  .NavBarMenuNavLinkDiv{
    display: none;

  }
  .link-sign-up{
   
    display: none;

  }
  .d-nn-nav1{
    display: none;
  }
}
@media (max-width:550px) {
  .NavbarMenu {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 75px;
}


}
@media (max-width:410px) {
  .search-in-div>input {
    width: 120px !important;
    
    }

}



@media (max-width:700px) {

  .search-in-div>input {
width: unset !important;
  }

}



@media (max-width:480px) {
.nav-use-r>img{
  display: none !important;
}

.search-section-d1{
display: none !important;
}

.NavBarMenuLogo {
  height: 80px;
}
.search-in-div>input {

  padding: 7px 4px !important;
 

}

}

.nav-op-btn{

  display: none;
}
.nav-use-r{
  display: flex;
align-items: center;
cursor: pointer;
gap: 5px;
}
.nav-use-r2{
  display: flex;
align-items: center;
cursor: pointer;
gap: 5px;
}

.NavbarMenu2Component2Span3{
  color: rgba(0, 0, 0, 0.514);
  font-weight: 600;
}
.NavbarMenu2Component2Span3:hover{
text-decoration: underline;
color: #F65000;


}
.add-details-rh{

  
  font-style: italic;
  color: white;
  font-family: "Zuume";
}

.dropbtn {

background-color: transparent;
border: none;
  cursor: pointer;
}

/* Style the container for the dropdown */
.dropdown {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {


  border-radius: 5px;
  gap: 10px;
  padding: 10px;
  flex-direction: column;

  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100  ;
  border-radius: 5px;
}

/* Style the dropdown links */
.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #333;
}

/* Change color on hover */
.dropdown-content a:hover {
  background-color: #ddd;


}

/* Show the dropdown content when hovering over the container */
.dropdown:hover .dropdown-content {
  display: flex;
  border-radius: 5px;
}
.nav-cat-spa{
  cursor: pointer;
  font-size: 15px;
  color: black;
}

.nav-cat-spa:hover{

  color: #F65000;
}



.nav-search-section{
  display: flex;
  gap: 30px;
}

.search-in-div>input{
    /* border-width: 1px; */
    padding: 9px 11px;
    /* padding-right: 55px; */
    transform: skewX(-10deg);
    outline: none;
    border: 1px solid #F65000;
    border-radius: 4px;
    background-color: white;
    width: 340px;

}
.search-in-div>input:focus{
  outline: none;


}


.search-data-nav>a{
  color: black;
  text-decoration: none;

}
.search-data-section-nav {
  background-color: white;
  width: 295px;
  max-height: 200px;
  padding: 10px;
  overflow-y: auto;

  border-radius: 5px;
}
.search-data-sec2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-res-af-ser{
  font-size: 14px;  
    font-weight: 600;
}
.search-data-nav>p{
  cursor: pointer;
  cursor: pointer;
  padding: 5px 0px;
  font-size: 13px;
}

.search-section-d1{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.search-tab-head-d1{
  position: absolute;
  z-index: 50000;
  top: 93px;
}

.nav-search-icon{
  color: black;
}