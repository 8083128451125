.card-h44 {
  display: flex;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
  align-items: center;
  flex-direction: column;
    
  }
  .card-heading54 {

    display: flex;
    padding: 20px;
  width: 100%;
/* 
    justify-content: space-between  ; */
    background: #f4f4f4;
    border-radius: 15px;
    gap: 20px;

  }
  @media only screen and (max-width: 1000px) {
    .card-heading54 {
       
      display: flex;
      justify-content: center;
      background: #f4f4f4;
      flex-direction: column;
      align-items: center;
    }
    .left-h {

      }
      .right-h {
        /* background: #f4f4f4; */
   
      }
}
@media only screen and (max-width: 600px) {
    .card-heading54 {
      width: 90%;
      display: flex;
      justify-content: center;
      background: #f4f4f4;
      flex-wrap: wrap;
    }
    .add-cart-b1 {
flex-direction: column;
    }
}
.left-h > img {
    width: 200px;
    border-radius: 5px;
  }
  .left-h {
    /* margin-top: 29px; */

    display: flex;
    /* justify-content: center; */
    align-items: center;
  
    /* width: 500px; */
  }
  .right-h1 {
    font-size: 20px;
  
  }
  .right-h {
    /* background: #f4f4f4; */

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
.right-texts > p {
    font-size: 15px;
    font-weight: 100;
  }
  .right-texts {
    line-height: 1.5;
  }
  .brand-n {
    font-size: 14px;
    color: dimgray;
    cursor: pointer;
    font-weight: 100;
    text-decoration: underline;
  }
  
.stars-p > svg {
    color: #f65000;
  }
  .stars-p {
    display: flex;
    align-items: center;
    line-height: 2;
  }
  .price-p {
    font-size: 8px;
    display: flex;
    gap: 14px;
  }
  
.line-thr {
    color: dimgray;
    font-weight: 100;
    text-decoration: line-through;
  }
  .dis-p {
    color: #f65000;
    font-weight: 100;
  }
  .add-cart-b1 {
    display: flex;
    gap: 2rem;
    align-items: center;
    transform: translateY(16px);
    width: 100%;
    display: none;
  }
  .shop-b > span:hover {
    display: inline-block;
    transform: skew(21deg);
    color: white;
    /* transform: skew(338deg, 360deg); */
  }
  .shop-b {
    background: white;
    padding: 11px;
    border-radius: 4px;
    border: none;
    color: white;
    transform: skew(338deg, 360deg);
  }
  .shop-b > span {
    display: inline-block;
    transform: skew(21deg);
  
    color: black;
  }
  .snip:hover .shop-b,
.snip.hover .shop-b {
  /* background: #f65000; */
  padding: 11px;
  border-radius: 4px;
  border: none;
}

@media only screen and (max-width: 1095px) {
    .snip:hover .shop-b, .snip.hover .shop-b {
      /* background: #f65000; */
      padding: 7px;
      border-radius: 4px;
      border: none;
  }
  .shop-b {
    background: white;
    padding: 7px;
    border-radius: 4px;
    border: none;
    color: white;
    transform: skew(338deg, 360deg);
}
}
.card-svg1 > svg {
    color: dimgray;
  }
  .card-svg1 {
    font-size: 24px;
    display: flex;
    gap: 16px;
  }
/* Container Tooltip */
.tooltip {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: dimgray;
    cursor: pointer;
  }
  
  /* Style text tooltip */
  .tooltipText {
    background-color: black;
    position: absolute;
    bottom: 130%;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0;
    white-space: pre;
    transition: all 0.5s;
  }
  
  .fb {
    color: white;
  }
  
  /*  Membuat dan style tip*/
  .tooltipText::after {
    content: "";
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 40%;
    margin-left: 5%;
  }
  
  /* Style Icon */
  
  /* Hover text tooltip */
  .tooltip:hover .tooltipText {
    opacity: 1;
    transform: translateY(-10px);
  }  
  .add-card-b:hover {
    background: #f65000;
  }
  .comp-svg {
    transform: rotate(140deg);
  }