
@import url('https://fonts.googleapis.com/css?family=Bangers&display=swap');

.ForumHeaderH1{
    font-size: 70pt;
  font-family: 'Luckiest Guy';
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:   0px -6px 0 #212121,  
                 0px -6px 0 #212121,
                 0px  6px 0 #212121,
                 0px  6px 0 #212121,
                -6px  0px 0 #212121,  
                 6px  0px 0 #212121,
                -6px  0px 0 #212121,
                 6px  0px 0 #212121,
                -6px -6px 0 #212121,  
                 6px -6px 0 #212121,
                -6px  6px 0 #212121,
                 6px  6px 0 #212121,
                -6px  18px 0 #212121,
                 0px  18px 0 #212121,
                 6px  18px 0 #212121,
                 0 19px 1px rgba(0,0,0,.1),
                 0 0 6px rgba(0,0,0,.1),
                 0 6px 3px rgba(0,0,0,.3),
                 0 12px 6px rgba(0,0,0,.2),
                 0 18px 18px rgba(0,0,0,.25),
                 0 24px 24px rgba(0,0,0,.2),
                 0 36px 36px rgba(0,0,0,.15);
}


.Forum{
    display: flex;
    flex-direction: column;
    align-items: center;

}


@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;500&display=swap');
.ForumBody{
    width: 80%;
}

.ForumBodyH1{
    margin-top: 50px;
    color: white;
    font-family: 'Mukta', sans-serif;
    font-weight: 500;
}

.ForumBodyp{
    color: white;
    font-family: 'Mukta', sans-serif;
    font-weight: 500;
}

.ForumCreatePost{
    margin-top: 25px;
    width: 100%;
    height: 220px;
    border-radius: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
}

.ForumCreatePostBody{
    width: 95%;
    height: 200px;
}


.ForumCreatePostBodyh3{
    font-family: 'Mukta', sans-serif;
    font-weight: bold;
    margin-top: 25px;
}


.ForumCreatePostBodyinput{
    margin-top: 10px;
    width: 100%;
    height: 80px;
    outline: none;
    border: none;
    background-color: rgba(211, 211, 211, 0.589);
    border-radius: 15px;
    padding-left: 25px;
}


.ForumCreatePostBodyButton{
    padding: 10px 18px;
    border-radius: 15px;
    border: none;
    background-color: #f65000;
    color: white;
    margin-top: 10px;
    cursor: pointer;
}


.ForumCreatePostBodyButton:hover{
    padding: 10px 18px;

    border-radius: 15px;
    border: none;
    background-color: #f65000;
    color: white;
    margin-top: 10px;
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.4s;
}


.ForumCreatePostBodydiv{
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}