.Pro-texts{
    font-family: "Lato", Arial, sans-serif;
    display: flex;
    margin: 40px;
    color: white;
    align-items: center;
    gap: 1px;
    flex-wrap: wrap;
}
.h-text{
    color: #999;
    font-size: 15px;
    font-weight: 100;
   cursor: pointer;
}
.h-text:hover{
    color: #f65000;
}
.apsp-text:hover{
    color: #f65000;

}
.apsp-text{
    font-size: 15px;
    font-weight: 100;
    color: #999;
    cursor: pointer;
 
}
.nat-text{
    font-size: 15px;
    font-weight: 100;
}
.arrow-span{
display: flex;
}


.carou-text2{
    font-family: "Odibee Sans", cursive;
    font-size: 30px;
    color: white;
    font-weight: 100;
    font-style: italic;
padding: 10px 0px;
}
.btn-fla-s {
    background: transparent;
    color: white;
    border: 1px solid gray;
    padding: 8px 5px;
    border-radius: 5px;
}
.btn-fla-s1{
    background: white;
    color: black;
    border: 1px solid gray;
    padding: 8px 5px;
    border-radius: 5px;
}
.btn-data-fla {
    display: flex;
    gap: 15px;
}
.selected-flv-h1{

    padding: 10px 0px;
    color: #999;
    font-size: 14px;
    font-weight: 100;
}

@media only screen and (max-width: 600px) {
    .carou-text {

        font-size: 25px !important;
    }
    .btn-dd-add{

        width: 100%;
        display: flex;
        justify-content: center;
    }

}


.btn-dd-add{
    display: flex;
    gap: 20px;
    align-items: center;
}

.add-cart-pre{
transition: all 0.2s;
    
    background: white;
    border: none;
    padding: 14px;
    width: 246px;
    border-radius: 5px;
    color: black;
    font-weight: bold;
}

.add-cart-pre:active{
    transform: scale(0.8);


}