.btn-hh {
    cursor: pointer;
    background-color:white;
    transform: skewX(-10deg) scaleX(0.955);
    overflow: hidden;
  
  
    // transform: translate(-50%, -50%);
    z-index: 0;
    box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.34);
    span {
      color: black;
      /* display: block; */
      /* padding-left: 35px; */
      text-transform: uppercase;
      /* font: bold 18px/66px Arial; */
      /* transform: scaleX(0.6); */
      letter-spacing: 1px;
      /* transform-origin: center left; */
      transition: color 0.3s ease;
      position: relative;
      z-index: 1;
      white-space: pre;
      display: inline-block;
      transform: skew(10deg);
   
      text-transform: uppercase;
    }
  
    &:before, &:after {
      content: '';
   
      background:#f65000;
      height: 50%;
      width: 0;
      position: absolute;
      transition: .3s cubic-bezier(.785,.135,.15,.86);
    }
    &:before {
      top: 0;
      left: 0;
      right: auto;
    }
    &:after {
      bottom: 0;
      right: 0;
      left: auto;
    }
    &:hover {
      &:before {
        
        width: 100%;
        right: 0;
        left: auto;
      }
      &:after {
        width: 100%;
        left: 0;
        right: auto;
      }
      span {
        color:white;
        white-space: pre;
        display: inline-block;
        transform: skew(10deg);
     
        text-transform: uppercase;
     
      }
     
    }
  }