

.Shop{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
}


.ShopHead{
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 15px;
    background-image: url('../../Assets//shop_breadcrumbs.jpg');
    height: 250px;
    position: relative;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 38px;
    background-position: center;
}

.ShopHeadH1{
    text-align: center;
    color: white;
    font-family: "Zuume";
    font-size: 65px;
}

.ShopHeadH3{
    text-align: center;
    color: white;
    font-family: "Zuume";
    font-size: 25px;
}


.ShopHeadSpan{
    color: #F56000;
    padding-right: 3px;
}