.Cart {
  width: 100%;
 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.CartContainer {

    padding: 25px;
   
    width: 80%;

}

.CartLeft {
  grid-area: CartLeft;

}

.CartLefth2{
    color: white;
    text-align: left;
}


.CartRight {
  grid-area: CartRight;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

}


.CartRighth2{
    color: white;
    text-align:end;
}


.ProductDetailRowImage{
    width: 150px;

}


.ProductDetailRow{
    display: flex;
    gap: 8px;
    align-items: center;
}



.ProductDetailPart2h4{
    font-weight: 400;
}


.ProductDetailPart2{
    display: flex;flex-direction: column;
row-gap: 5px;
    height: 100%;
}


.CartQuantityInput{
    width: 60px;
padding: 15px 0px 13px 13px;
/* border-right: none; */

}


.buttonInputField{
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: -45.5px;
    margin-left: 40px;
}


.buttonInputField button{
    background-color: #f65000;
    border: none;
    padding: 3px;
    color: white;
    cursor: pointer;
}

.buttonInputField button:hover{
    background-color: #000000;
    border: none;
    padding: 3px;
    color: white;
    
}

.titleh4wdsfsdf{
    font-weight: bold;
}


@media (max-width: 850px) {
    .ProductDetailRow {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-direction: column;
    }
    .table-header{
        display: none !important;
    }

    .buttonInputField{
        display: flex;
        flex-direction: column;
        position: absolute;
        margin-top: 19px !important;
        margin-left: 40px;
    }
    .cart-p-box2{
display: none !important;
    }

    .cart-p-box1{
        display: flex !important;
    }
    

    .CartContainer {
        max-width: unset !important;
        margin-left: unset !important;
        margin-right: unset !important;
        padding-left: unset !important;
        padding-right: unset !important;
    
    }
    .CartContainer {
        padding: 25px;
        width: 95% !important;
    }

    .Cart {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

}
.cart-p-box2{
    
}
.cart-box-d1>img{
    width: 80px;

}


.mt-cart-1{
    margin-top: 10px;
}


.cart-mob-d1>h1{
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none!important;
    color: black;
}
.cart-mob-d1 {
    border-bottom: 1px solid gray;
    padding: 0px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.cart-mob-d3>h1{
    font-size: 13px;
    text-transform: uppercase;
    color: rgb(41, 40, 40);
    font-weight: 400;
}
.cart-mob-d3 {
    border-bottom: 1px solid gray;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart-mob-d4>h1{
    font-size: 13px;
    text-transform: uppercase;
    color: rgb(41, 40, 40);
    font-weight: 400;
}
.cart-mob-d4 {

    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart-mob-d5>h1{
    font-size: 15px;
    text-transform: uppercase;
    color: rgb(41, 40, 40);
    font-weight: 400;
}
.cart-mob-d5 {


    display: flex;
    align-items: center;
 gap: 5px;
}

.cart-mob-d5>button {
    border: none;
    background: #f65000;
    padding: 7px 10px;
    color: white;
    border-radius: 5px;
}



.cart-box-d2{
        width: calc(100% - 100px);
}

.cart-p-box1{
background-color: white;
padding: 15px 10px;
    width: 100%;
display: none;
border-radius: 15px;
}


.cancelbuttoncart button{
border: none;
background-color: transparent;
cursor: pointer;
}


.cancelbuttoncart button:hover{
    border: none;
    background-color: transparent;
    color: red;
    transform: scale(1.2);
    transition: ease-in 0.4s;
    }

.CardRightCard{
  
    width: 300px;
    background-color: white;
    border-radius: 15px;
    padding: 25px 25px;
}



.CardRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.CardRightTop{
    display: flex;
    justify-content: space-between;
}

.CardRightTop h4{
    font-weight: 400;
}

.CardRightTop h4:nth-child(1){
    font-weight: bold;
}


.checkoutbutton{
    width: 100%;
    height: 35px;
    margin-top: 8px;
    background-color: grey;
    border: grey;
    border-radius: 15px;
    color: white;
    cursor: pointer;
}


.checkoutbutton:hover{
    transform: scale(1.07);
    transition: ease-in-out 0.5s;
    background-color: #f65000;
}
.data-total{
    display: flex;
    align-items: center;
    gap: 5px;
}


@media (max-width: 1050px) {
    .ProductDetailRowImage {
        width: 100px !important;
    }
    .ProductDetailPart2h4 {

        font-size: 14px;
    }

}
@media (max-width: 400px) {
    .cart-mob-d1>h1 {
       width: 180px  !important;
       font-size: 13px !important; 
    }


}
.item-delete-cart{
    background-color: transparent;
    border: none;
}
@media (max-width: 600px) {
    .CardRightCard {
        width: 100%;
    
    }
}

.box-c-in-dec{
    
}