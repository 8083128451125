* {
  margin: 0;
  padding: 0;
}
@import url("https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap");

@media only screen and (max-width: 450px) {
  .legal {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    color: #999;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media only screen and (max-width: 500px) {
  .footer__addr {
    margin-right: 0em !important;
    margin-bottom: 2em;
  }
  .contact-ff {
    background: white;
    padding: 30px;
    display: flex;
    gap: 1rem;
    border-radius: 15px;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1 !important;
  }

  .nav__item--extra {
    flex-grow: 2 !important;
  }

  .footer__addr {
    flex: 1 0px !important;
  }

  .footer__nav {
    flex: 2 0px !important;
  }
}

.nav__ul a:hover {
  color: #f65000;
}
.nav-usocial li:hover {
  color: #f65000;
}
.svg-card2 {
  font-size: 30px;
}
.svgs-cards1 {
  gap: 6px;
  display: flex;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 30px 0px 30px;
  color: white;
  background-color: black;

  font-family: "Lato", Arial, sans-serif;
}

.footer > * {
  flex: 1 100%;
}

.footer__addr {
  margin-right: 8.25em;
  margin-bottom: 2em;
}

.footer__logo {
  font-family: "Zen Dots", cursive;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 1.5rem;
  line-height: 2;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.nav__title {
  font-weight: 400;
  font-size: 15px;
}

.footer address {
  font-style: normal;
  color: #999;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  font-size: 13px;
  color: #999;
  white-space: pre;
}
.footer-p {
  color: #999;
}
.nav__ul--extra {
  column-count: 2;
  column-gap: 1.25em;
}
.legal {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  color: #999;
  align-items: center;
  justify-content: space-between;
}

.legal__links {
  display: flex;
  align-items: center;
}
.social-mm {
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bb-brs {
  display: none !important;
}
@media only screen and (max-width: 650px) {
  .bb-brs {
    display: unset !important;
  }

  .social-mm {
    flex-direction: column;
  }
}
.footer__addr {
  flex: 1 1;
}
.have-a {
  padding: 10px;
}
.svg-mess {
  color: #f65000;
  font-size: 30px;
}
.h1-q {
  color: #999;
  white-space: pre;
  font-size: 16px;
}
.h-ask {
  font-size: 22px;
  font-weight: 100;
  white-space: pre;
}
.fle-sv-h {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ul-1 > ul {
  display: flex;
  align-items: center;
  gap: 1rem;

  flex-wrap: wrap;
  justify-content: center;
}

.ul-1 li {
  font-size: 13px;
  cursor: pointer;
}

.phone-svgff {
  color: #f65000;
  font-size: 25px;
}
.font-f {
  font-family: "Lato", Arial, sans-serif;
}

.hot-t {
  color: #999;
  font-size: 15px;
  font-weight: 100;
  text-transform: uppercase;
}
.hot-t1 {
  color: black;
  font-size: 17px;
  font-weight: 100;
  white-space: pre;
}
.contact-f {
  background: white;
  padding: 30px;
  display: flex;
  gap: 1rem;
  /* border-right: 1px solid black; */
  border-left: 1px solid black;
}
.contact-f2 {
  background: white;
  padding: 30px;
  display: flex;
  gap: 1rem;
  border-right: 1px solid black;
  border-left: 1px solid black;
}
.contact-ff {
  background: white;
  padding: 30px;
  display: flex;
  gap: 1rem;
  border-radius: 15px;
}
.border-con {
  display: flex;
  align-items: center;
  background: white;
  margin: 10px;
  border-radius: 15px;
  margin-bottom: 50px;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1170px) {
  .contact-f {
    border-left: 0px solid black;
  }
  .contact-f2 {
    border-right: 0px solid black;
    border-left: 0px solid black;
  }
}

.iconFooter {
  height: 30px;
  width: 30px;
  padding-left: 8px;
}

@media only screen and (max-width: 896px) {

.border-con{
  display: none;
}
.contact-f {
  background: white;
  padding: 30px;
  display: flex;
  gap: 1rem;
  /* border-right: 1px solid black; */
  border-left: 1px solid black;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.f-f--1{
  display: unset !important;

}
}
.f-f--1{
  display: none;
}

.f-f2 {

  margin: 14px;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}




  .contact-f {
    border: none !important;

  }

  .contact-f2 {
    border: none !important;

  }




