.ForumCard{
    min-height: 450px;
    min-width: 330px;
    max-height: 450px;
    max-width: 330px;
    background-image: url('../../../Assets/blog_8-820x500.jpeg');
    background-position: -150px;
    /* filter: brightness(0.5); */
    border-radius: 10px;
    background-repeat: no-repeat;
padding: 40px;
}


@media (max-width:700px) {
    .ForumCard{
        min-height: 450px;
        min-width: 330px;
        max-height: 450px;
        max-width: 330px;
        background-image: url('../../../Assets/blog_8-820x500.jpeg');
        background-position: -150px;
        /* filter: brightness(0.5); */
        border-radius: 10px;
        background-repeat: no-repeat;
    padding: 40px;
    margin-bottom: 10px;
    }
}


.ForumCardTop{
    display: flex;
    align-items: center;
    gap: 8px;
}


.ForumCardSpan{
    margin-top: -3px;
    width:5px;
    height: 5px ;
    background-color: white;
    transform: skew(-15deg);
}

.ForumCardP{
color: #F65000;
font-family: 'Zuume';
font-size: 16px;

}


.ForumCardP1{
    color: grey;
    font-family: 'Zuume';
    font-size: 16px;
    cursor: pointer;
    }


    
.ForumCardP1:hover{
    color: #F65000;
    font-family: 'Zuume';
    text-decoration: underline #F65000;
    font-size: 16px;
    }


    .ForumCardP2{
color: white;
font-family: 'Zuume';
font-size: 20.5px;
margin-top: 5px;
font-style: italic;
word-spacing: 1.3px;
    }


    .ForumCardP2:hover{
        cursor: pointer;
        color: #F65000;
        font-family: 'Zuume';
        font-size: 20.5px;
            }

.ForumCardBottom{
    margin-top: 260px;
}


            .ForumCardButton{
                cursor: pointer;
                padding: 9px;
                border-radius: 5px;
                font-weight: 400;
                outline: none;
                border: none;
                transform: skew(-15deg);
transition: 0.3s;
            }


            .ForumCardButton:hover{
                padding: 9px;
                border-radius: 5px;
                font-weight: 400;
                outline: none;
                border: none;
                background-color: #F65000;
                transform: skew(-15deg);
                color: white;

            }