.ProductCard{
    height: 385px;
    width: 250px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    
}

a:-webkit-any-link {
    color: none !important;
    cursor: pointer;
    text-decoration: none !important;;
}

.ProductCardImage{
    width: 250px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 250px;
    object-fit: contain;

}

.ProductCardSpan{
    font-size: 13px;
    text-decoration: underline;
    color: grey;
    margin-left: 25px;
cursor: pointer;
}

.ProductCardSpan:hover{
    font-size: 13px;
    text-decoration: underline;
    color: #F65000;
    margin-left: 25px;

}

.ProductCardSpan1{
    font-size: 13px;
    font-weight: bold;
    margin-top: 8px;
    margin-left: 25px;
    cursor: pointer;

}

.ProductCardSpan1:hover{
    font-size: 13px;
    font-weight: bold;
    color: #F65000;
    margin-left: 25px;

}

.ProductCardDiv{
    margin-left: 25px;
    margin-top: 8px;
    display: flex;
    color: grey;
    gap: 5px;
}

.ProductCardSpan2{
    margin-left: 25px;
    margin-top: 15px;
}

.ProductCardButton{
    bottom: 0;
    position: absolute;
width: 100%;
height: 45px;
background-color: black;
color: white;
display: none;
border: 1px solid white;
margin-top: 10px;
cursor: pointer;

}

.ProductCard:hover .ProductCardButton{
    width: 100%;
    height: 45px;
    background-color: black;
    color: white;
    display: block;
    border-radius: 0 0 10px 10px;
    border: 1px solid white;
    margin-top: 10px;
    }

    .ProductCard:hover .ProductCardSpan2{
        display: none;
    }

    .ProductCard:hover .ProductCardImage{
        width: 250px;
        margin-top: -80px;
    }




    .IconButtonCard{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

.IconButtonCard{
    height: 30px;
    width: 30px;
    background-color: white;
    color: black;
    border-radius: 15px;
    text-align: center;
}

.IconButtonCard:hover{
    height: 30px;
    width: 30px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border-radius: 15px;
    text-align: center;
    transition: 0.6s;
}



.IconButtonCard1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.IconButtonCard1{
    height: 30px;
    width: 30px;
background-color: white;
color: black;
border-radius: 15px;
text-align: center;
margin-top: 5px;
margin-bottom: 5px;
cursor: pointer;
}

.IconButtonCard1:hover{
    height: 30px;
    width: 30px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
border-radius: 15px;
text-align: center;
margin-top: 5px;
margin-bottom: 5px;
cursor: pointer;
}



.IconButton{
    /* position:absolute; */
    z-index: 3;
    display: none;
    flex-direction: column;
    margin-left: 210px;
    margin-top: 20px;
}


.ProductCard:hover .IconButton{

    display: flex;
    flex-direction: column;
    margin-left: 210px;
    margin-top: 20px;
}