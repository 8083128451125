.VlogPlaylistCard{
    width: 100%;
    height: 100px;
    background-color: #393c3de1;
    display: flex;
    cursor: pointer;
    transition: cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-bottom: 7px;

}

.VlogPlaylistCard:hover{
    width: 100%;
    height: 100px;
    background-color: #818181e1;
    display: flex;

}

.VlogPlaylistCardLeft{
    width: 30%;
    height: 100%;
}

.VlogPlaylistCardLeft img{
    padding: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
}


.VlogPlaylistCardRight{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
}

.VlogPlaylistCardRight p{
font-size: 13px;
font-weight: bold;
overflow: hidden;
padding: 10px 10px;
color: white;
}


@media (max-width:1350px) {
    .VlogPlaylistCardRight p{
        font-size: 12px;
        font-weight: bold;
        overflow: hidden;
        padding: 10px 10px;
        color: white;
        }
}



@media (max-width:1150px) {
    .VlogPlaylistCardRight p{
        font-size: 11px;
        font-weight: bold;
        overflow: hidden;
        padding: 10px 10px;
        color: white;
        }
}


@media (max-width:1000px) {
    .VlogPlaylistCardRight p{
        font-size: 10px;
        font-weight: bold;
        overflow: hidden;
        padding: 10px 10px;
        color: white;
        }
}



@media (max-width:900px) {
    .VlogPlaylistBottom{
        display: flex !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.VlogPlaylistBottom{
    display: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.VlogPlaylistBottom2{
    margin-top: 10px;
    width: 70%;
    height: 500px;
    overflow-y: scroll;
}


.VlogPlaylistBottom2::-webkit-scrollbar {
    width: 5px;

  }
  
  /* Track */
  .VlogPlaylistBottom2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .VlogPlaylistBottom2::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .VlogPlaylistBottom2::-webkit-scrollbar-thumb:hover {
    background: #f65000; 
  }

@media (max-width:500px) {
    .VlogPlaylistBottom2{
        margin-top: 10px;
        width: 95%;
    }
}