@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;700&display=swap');



.Home{
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    background-color:#000000;
 
}



.swiper {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  user-select: none;
  
}

.swiper-slide {

  background:#000000;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}






@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');





.SwiperSliderImage{
    /* width: 130%; */
    height: 35vw;
    width: 100%;
    border-radius: 15px;
}
@media (max-width:600px) {
    .SwiperSliderImage{
        /* width: 130%; */
        height: 60vw;
        width: 100%;
        border-radius: 15px;
    }


}


.SwiperSliderImageIcon{
    position: absolute;
    width: 70vw;
    margin-top: 8vw;
    z-index: 2;
}

.SwiperSliderImageIcon1{
    position: absolute;
    width: 30vw;
    margin-top: 4vw;
    margin-left: -50vw;
    z-index: 2;
}

@import url('https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap');
.SwiperSliderImageP{
    margin-top: -22vw;
    position: absolute;
    z-index: 1;
    font-family: 'Holtwood One SC', serif;
    height: auto;
    width: auto;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: normal;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: center;
    line-height: 61px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 5vw;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
}


.SliderProteinCartel1{
    position: absolute;
    z-index: 1;
    color: rgba(255, 255, 255, 0.795);
    font-weight: bold;
    font-family: 'Passion One', cursive;
    text-transform:uppercase;
    font-size: 7vw;
    margin-top: -20vw;
}


.SwiperSliderImageP2{
    color: #F65000;
    position: absolute;
    z-index: 1;
    font-size: 1.2vw;
    font-weight: bold;
    margin-top: -15vw;
    margin-left: 28vw;
}


.SliderProteinCartel2{
    position: absolute;
    z-index: 1;
    color: rgba(255, 255, 255, 0.795);
    font-weight: bold;
    font-family: 'Luckiest Guy', cursive;
    font-size: 4vw;
    margin-top: -8vw;
    margin-left: 28vw;
}
.SliderProteinCartel3{
    position: absolute;
    z-index: 1;
    color: rgba(255, 255, 255, 0.795);
    font-weight: bold;
   
    font-size: 2vw;
    margin-top: -1vw;
    margin-left: 28vw;
}

@media (max-width:650px) {
    .SliderProteinCartelButton1{
        position: absolute;
        margin-top: 21.5vw !important;
        margin-left: -50vw;
        padding: 1.3vw;
        transform: skewX(-15deg)!important;
        font-weight: bold;
        cursor: pointer;
        
    }
}


.SliderProteinCartelButton{
    position: absolute;
    margin-top: 9vw;
    margin-left: 28vw;
    padding: 1.3vw;
    transform: skewX(-15deg)!important;
    font-weight: bold;
    cursor: pointer;
    
}

.SliderProteinCartelButton:hover{
   color: white;
   background-color: #F65000;
   transition: 0.5s;
    
}


.SwiperSliderImageRotate{
    height: 35vw;
    width: 100%;
    border-radius: 15px;
    rotate: 180deg;
}

.SwiperSliderImageRotate1{
  
    height: 35vw;
    position: absolute;
    filter: brightness(0.8);
    margin-left: 80vw;
    
}

.SwiperSliderImageIcon11{
    position: absolute;
    width: 55vw;
    margin-top: 4vw;
    margin-left: 40vw;
    z-index: 2;
}

.SwiperSliderImageP22{
    color: #F65000;
    position: absolute;
    z-index: 1;
    font-size: 2.2vw;
    font-weight: bold;
    margin-top: -20vw;
    margin-left: -45vw;
    
}


.SliderProteinCartel22{
    position: absolute;
    z-index: 1;
    color: rgba(255, 255, 255, 0.795);
    font-weight: bold;
    font-family: 'Luckiest Guy', cursive;
    font-size: 5.5vw;
    margin-top: -5vw;
    margin-left: -50.5vw;
}


.SliderProteinCartel32{
    position: absolute;
    z-index: 1;
    color: rgba(255, 255, 255, 0.795);
    font-weight: bold;
   
    font-size: 2vw;
    margin-top: 10vw;
    margin-left: -47vw;
}

.SliderProteinCartelButton1{
    position: absolute;
    margin-top: 19vw;
    margin-left: -50vw;
    padding: 1.3vw;
    transform: skewX(-15deg)!important;
    font-weight: bold;
    cursor: pointer;
    
}

.SliderProteinCartelButton1:hover{
    color: white;
    background-color: #F65000;
    transition: 0.5s;
     
 }

 /*      SkinTop        */



 .SkinHomeTop{
    height: 50px;
    display: flex;
    background-color: #FEE5D9;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 10px;
 }


 @media (max-width:600px) {
    .SwiperSliderImageRotate{
        height: 60vw;
        width: 100%;
        border-radius: 15px;
        rotate: 180deg;
    }

    .SwiperSliderImageRotate1{
  
        height: 60vw;
        position: absolute;
        filter: brightness(0.8);
        margin-left: 80vw;
        
    }
 }

 .SkinHomeTop1{
   
    display: flex;
    width: 33.333%;
    justify-content: center;
 }

 .SkinHomeTop1Icon{
    height: 20px;
    width: 20px;
    margin-right: 5px;
 }

 .SkinHomeTop1Icon:hover{
    transform:scale(0.8);
    transform-origin:50% 50%;
    transition: 0.6s;
    

 }

 @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,600;1,600&display=swap');


 .SkinHomeTop1Span{
    font-family: 'Plus Jakarta Sans', sans-serif;
    
    font-size: 13px;
    user-select: none;
 }

 .SkinHomeTop11{
    display: flex;
    border-style: solid;
    border-width: 0 1px;
    border-color: #000;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: center;
    width: 33.333%

 }

 @media (max-width:770px) {
    .SkinHomeTop{
        display: none;
    }
 }


.HomeShopBy{
    
width: 100%;
display: flex;
justify-content: center;
user-select: none;
flex-direction: column;
align-items: center
}

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@800&display=swap');


.HomeShopByTitle{
    margin-top: 50px;
   justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    user-select: none;
}

.HomeShopByTitle1{
    margin-top: 50px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    user-select: none;
    margin-left: 80px;
}

 .HomeShopBySpan{
    color: white;
    font-weight: 800;
    font-size: 25px;
    margin-right: 5px;
    font-family: 'Plus Jakarta Sans', sans-serif;
 }


 /*Card*/

 .HomeShopByCard{
    display: flex;
  /* transform: scale(1vw); */
flex-direction: column;
/* margin-bottom: 50px; */
justify-content: center;
align-items: center;
cursor: pointer
 }

.HomeShopByCardBody{
    width: 350px;
    height: 400px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.HomeShopByCardBodyMid{
    width: 70%;
    height: 180px;
    background-color: #f4f4f46c;
 transform: skewX(-10deg);
 cursor: pointer;
 border-radius: 10px;
}


.HomeShopByCardBodyMid:hover{
    width: 70%;
    height: 180px;
    background-color: #F65000;
 transform: skewX(-10deg);
 transition: 0.6s;

}


.HomeShopByCardBodyMidImage{
    position: absolute;
    width: 350px;
}

.HomeShopByCardBodyMidSpan{
    color: white;
    font-weight: bold;
    font-size: 19px;
 transform: skewX(-15deg);


}

.HomeShopByCardBodyMidSpan2{
    color: white;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
    border-bottom: 1px solid #F65000;
    padding-bottom: 5px;
}

.HomeShopByCard:hover  .HomeShopByCardBodyMid{
    width: 70%;
    height: 180px;
    background-color: #F65000;
 transform: skewX(-10deg);
 transition: 0.6s;


}

.HomeShopByCard:hover  .HomeShopByCardBodyMidImage{
    position: absolute;
    transform: scale(1.1);
    transition: 0.7s;
}



.CardHome{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -40px;
   
}


.ProductsHome{
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid grey;
    padding-bottom: 80px;
    margin-left: 20px;
    margin-right: 20px;

}

@media (max-width:600px) {
    .CardCategory{
        width: 150px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.CardCategory{
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CardCategoryImage{
    height: 200px;
}

.CardCategorySpan{
    color: white;
    font-weight: bold;
}


.CardCategory:hover .CardCategoryImage{
  transform: scale(1.1);
  transition: all 0.3s;
}


.CardCategory:hover .CardCategorySpan{
    color:  #F65000;
  transition: 0.7s;

  }



  .ProductSliderOff{
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    border-radius: 15px;
    margin-left: 20px;
    margin-right: 20px;
    height: 400px;
    background-image: url('../../Assets//CarouselBackground//h1_bg1.jpg');

  }

  .ProductSliderImage{
    border-radius: 15px;
    
   width: 100%;
    height: 400px;
  }


  .ProductSliderOffLeft{
    width: 35%;
  }

  .ProductSliderOffRight{
    width: 65%;
  }

.ProductSliderOffLeft{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
  .ProductSliderOffLeft1{
    color: white;
    font-family: 'Zuume';
    font-size: 55px;
    font-style: italic;
  }

  .ProductSliderOffLeft2{
    color: #F65000;
    font-family: 'Zuume';
    font-size: 55px;
    font-style: italic;
  }


  .ProductSliderOffLeft3{
    color: #888888;
    font-size: 13px;
    font-weight: bold;
  }

  .ProductSliderOffLeft4{

    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 2px solid #F65000;
    padding-bottom: 2px;
    width: fit-content;
    cursor: pointer;
  }


  .ProductSliderOffLeft4:hover{

    color:#F65000;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 2px;
    width: fit-content;
    transition: 0.4s;
  }

  .ProductSliderOffLEftLEft{
    display: flex;
    flex-direction: column;
  }







  

.timers{
    margin-top: 10px;
    margin-left: -15px;
    margin-bottom: 10px;
}

#timer div {
  text-align: center;
  background: #F65000;
  color: #ffffff;
  width: 50px;
  height: 55px;
  border-radius: 3px;
  font-size: 35px;
  font-weight: 700;
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 50px;
  transform: skew(-15deg);
}
#timer div span {
  display: block;
  margin-top: -42px;
  margin-left: 2px;
  font-weight: bold;
  position: absolute;
  
  font-size: 33px;
  font-weight: 700;
}
.timers{
  /* flex-wrap:dis ; */
  display: flex;
  justify-content:center;
}
.day{
  /* align-items:center; */
  text-align: center;
   flex:column;
    /* display:flex;  */
    justify-content:center;

}
.tim{
  margin-top: 10rem;
}
.inputt{
  display: flex;
  justify-content: center;
  padding: 50px;
}
.btnweeksout{
  display: flex;
  justify-content: center;
  padding: 50px;
}









.ProductSliderOffRight{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
   gap: 5px;
   width: 65%;
}


@media (max-width:1250px) {

    .ProductSliderOff {
        padding: 25px;
        display: flex;
        flex-direction: column;
        margin-top: 80px;
        border-radius: 15px;
        margin-left: 20px;
        margin-right: 20px;
        height: fit-content;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .ProductSliderOffRight {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        gap: 5px;
        width: fit-content;
        justify-content: center;
    }


    .ProductSliderOffLEftLEft {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }

    .ProductSliderOffLeft {
        width: fit-content;
    }
    
}



.HomeVideoSLide{
    
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    height: 600px;
    margin-left: 20px;
    margin-right: 20px;
    /* background-color: white; */
    border-radius: 10px;
}

.HomeVideoSLideLeft{
width: 35%;
height: 100%;
background-color: white;
border-radius:  10px 0px 0px 10px;
padding-left: 30px;
padding-top: 50px;
padding-right: 10px;
}

.HomeVideoSLideLeftP{

    font-family: 'Zuume';
    font-size: 55px;
    font-style: italic;
}


.HomeVideoSLideLeftP2{
    margin-top: 10px;
    font-size: 17.5px;
    line-height: 25px;
    color: lightslategray;
   
    font-weight: 500;
    padding-bottom: 50px;
    border-bottom: 1px solid lightslategray;
    padding-right: 15px;
    
}


.HomeVideoSLideCenter{
    position: absolute;
    z-index: 10;
    margin-top: 180px;
    margin-left: 30px;
    height: 300px;
    width: 35%;
    background-color: rgb(255, 255, 255);
}



.HomeVideoSLideRight{
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
   
    width: 65%;
    height: 100%;
  
  
   
    cursor: pointer;
    background-color: rgb(224, 221, 221);
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;


}












.HomeVideoSLideRightiframe{
    height: 600px;
    width: 100%;
    z-index: 1;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}


.HomeVideoSLideLeftNumber{
    margin-top: 35px;
    display: flex;
    gap: 45px;
}

.HomeVideoSLideLeftNumber1p{
    color: #F65000;
    font-family: 'Zuume';
    font-style: italic;
    font-size: 50px;
}


.HomeVideoSLideLeftNumber1span{

    
    font-style: italic;
    font-size: 15px;
}


@media (max-width:1083px) {
    .HomeVideoSLideLeftNumber {
        margin-top: 24px;
        display: flex;
        gap: 29px;
    }

    .HomeVideoSLideLeftP2 {
        margin-top: 10px;
        font-size: 17.5px;
        line-height: 25px;
        color: lightslategray;
        font-weight: 500;
        padding-bottom: 15px;
        border-bottom: 1px solid lightslategray;
        padding-right: 15px;
    }
}


@media (max-width:1040px) {
    .HomeVideoSLide {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        height: 600px;
        margin-left: 20px;
        margin-right: 20px;
        /* background-color: white; */
        border-radius: 10px;
        align-items: center;
    }

    .HomeVideoSLideLeft {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        padding-left: 30px;
        padding-top: 50px;
        padding-right: 10px;
        padding-bottom: 20px;
    }

    .HomeVideoSLideRight {
        /* border-bottom-right-radius: 0px; */
        border-top-right-radius: 0px;
        border-bottom-right-radius: 10px;
        /* border-top-right-radius: 10px; */
        border-bottom-left-radius: 10px;

        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: rgb(224, 221, 221);
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .HomeVideoSLideRightiframe {
        height: 600px;
        width: 100%;
        z-index: 1;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
    }
    

  
    
}


.HomeCustomerReviewsHead{
margin-top: 100px;
display: flex;
align-items: center;

gap: 15px;
}


.HomeCustomerReviewsHeadSpan1 {
    width: 75%;
    height: 1px;
    display: block;
    border-top: 1px solid lightslategray;
    /* position: absolute; */
}


.HomeCustomerReviewsHeadSpan2{
   display: flex;
   width: 25%;
   align-items: center;
   font-size: 45px;
   font-family: 'Zuume';
   font-style: italic;
   gap: 5px;
   color: white;
}


@media (max-width:1035px) {
    .HomeCustomerReviewsHead {
        margin-top: 500px;
        display: flex;
        align-items: center;

        gap: 15px;
    }
}


@media (max-width:595px) {
    .HomeCustomerReviewsHeadSpan1 {
        width: 60%;
        height: 1px;
        display: block;
        border-top: 1px solid lightslategray;
        /* position: absolute; */
    }
    
    
    .HomeCustomerReviewsHeadSpan2{
       display: flex;
       width: 40%;
       align-items: center;
       font-size: 45px;
       font-family: 'Zuume';
       font-style: italic;
       gap: 5px;
       color: white;
    }

    .HomeCustomerReviewsHeadSpan2Icon{
     display: none;
    }
    
}

.HomeCustomerReviews{
    display: flex;
    width: 100%;

}


.ShopByBrandHome{
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
    flex-wrap: wrap;
}


.ShopByBrandHome .BrandCard:nth-child(odd){
    background-color: rgb(194, 194, 194);
}


@media (max-width:450px) {

.HomeCustomerReviewsHead {
    margin-top: 300px !important;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    gap: 15px;
}
}


@media (max-width:660px) {
    .HomeShopByTitle1 {
        margin-top: 50px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        -webkit-user-select: none;
        user-select: none;
        margin-left: 0px;
    }

    .HomeCustomerReviewsHead {
        margin-top: 150px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
        gap: 15px;
    }

    .BrandCard {
        min-width: 165px;
        min-height: 180px;
        background-color: lightgray;
        max-width: 165px;
        max-height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        /* border-radius: 10px; */
    }

    .HomeVideoSLideRightiframe {
        height: 260px;
        width: 100%;
        z-index: 1;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
    }
}


.HomeForum{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
}


.HomeForumScroll{
    display: flex;
    flex-direction: row;
    gap: 20px;

}
