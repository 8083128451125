.WishlistTopp1{
    color: white;
    cursor: pointer;
}


.WishlistTopp1:hover{
    color: #f65000;
    text-decoration: underline;
}


.WishlistTopp11{
    color: white;
    cursor: pointer;
}

.WishlistTopp2{
    color: gray;
}

.WishlistTop{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 7px;
}

.WishlistTop2{
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 25px;
}


.WishlistTop3{
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 5px;
}
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');

.WishlistTop2h3{
    font-size: 45px;
    font-family: 'Oswald', sans-serif;
}


.WishlistBodyTable{
    width: 80%;

}


.WishlistBody{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}


.wishlistaddtocart{
    width: 100px;
    height: 40px;
    border-radius: 15px;
    border: none;
    background-color: #f65000;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.wishlistaddtocart:hover{
    width: 100px;
    height: 40px;
    border-radius: 15px;
    border: none;
    background-color: #f65000;
    color: white;
    font-weight: bold;
    transform: scale(1.05);
    transition: ease-in 0.5s;
}


.ProductDetailPartIcon:hover{
color: red;
cursor: pointer;
transition: ease-out 0.4s;
transform: scale(1.05);
}



.wish-mob-d3>h1{
    font-size: 13px;
    text-transform: uppercase;
    color: rgb(41, 40, 40);
    font-weight: 400;
}
.wish-mob-d3 {

    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wish-p-box1 {
    background: white;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    align-items: center;
}
.wish-box-d2{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
@media (max-width: 700px) {

.WishlistBodyTable {
    width: 95% !important;
}

}

.wish-mob-d1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wish-mob-d1>h1{

 font-size: 13px;

    color: rgb(41, 40, 40);
    font-weight: bold;
}
@media (max-width: 850px) {

    .wish-p-box1{
        display: flex !important;
    }
}

.wish-p-box1{
    display: none ;
}