.TrendingCard{
    width: 380px;

display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
cursor: pointer;
}


.TrendingCardImage{
    width: 95%;
    height: 210px;
    object-fit: cover;
}

.TrendingCardTag{
    background-color: #f65000;
    width: fit-content;
    color: white;
    font-weight: 500;
    font-family: "Roboto";
    font-size: 13px;
    padding: 7px 15px 5px 15px;
}

.TrendingCardTrendingCard{
    width: 95%;
    margin-top: 15px;
}


.TrendingCardBody{
    margin-top: 20px;
    font-size: 17px;
    font-weight: 400;
    color: white;
    padding-bottom: 50px;
    font-family: "Roboto";
}