.BrandCard{
    min-width: 200px;
    min-height: 200px;
    background-color: lightgray;
    max-width: 200px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* border-radius: 10px; */
}

.BrandCard:hover{
    min-width: 200px;
    min-height: 200px;
    background-color: lightgray;
    max-width: 200px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: brightness(0.7);
    cursor: pointer;
    /* border-radius: 10px; */
}


.BrandCardImg{
  width: 100px;
  height: 100px;
  filter: grayscale(1);
  opacity: 0.5;
  transition: 0.4s;
}


.BrandCard:hover .BrandCardImg{
    width: 100px;
    height: 100px;
    filter: grayscale(1);
    opacity: 1;
    transform: scale(1.1);
}

