.check-uppr{
    display: flex;
    align-items: center;
}
.check-h1{
    color: #999;
    font-size: 15px;
    font-weight: 100;
    cursor: pointer;
    cursor: pointer;


}
.check-h2{

    
    color: white;
    font-size: 15px;
    font-weight: 100;
}
.check-out-head1{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.check-out-head{
    width: 95%;
}

/* App.css */

  .form-container {
    /* max-width: 400px;
    margin: 0 auto; */
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }
  
  .check-label {
    display: block; /* Labels are displayed as block elements */
    margin-bottom: 5px;
    font-weight: bold;
    color: white;
  }
  
  .input-check{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
.check-out-left-h{

width: 40%;
}
.check-out-right-h{

    width: 40%;



}

.check-form-d1{
    display: flex;
    gap: 10px;
}
.left-h-h1{
    align-items: center;
    font-style: italic;
    color: white;
    font-family: "Zuume";
}
.left-head-c{
    padding: 20px 0px;
}
.check-out-head-dv{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
}
.left-bb-check{
    border: 2px solid white;
    border-radius: 5px;

    padding: 10px;
}
.rh-check-con1{
    display: flex;
    align-items: center;
     justify-content: space-between;
      border-bottom: 1px solid gray;
      padding: 10px 0px;
}
.rh-check-con1>h1{
    color: white;
    font-size: 18px;
    font-weight: 400;


}
.content-right-hcheck{
    
    display: flex;
    gap: 10px;
    flex-direction: column;

}


.rh-check-con2{
    display: flex;
    align-items: center;
     justify-content: space-between;
      border-bottom: 1px solid gray;
      padding: 10px 0px;
}
.rh-check-con2>h1{
    color: white;
    font-size: 14px;
    font-weight: 400;


}
.rh-ch-h1{
    width: 235px;
}
.rh-ch-h2{
    font-size: 14px !important;
}



.rh-check-con3{
    display: flex;
    align-items: center;
     justify-content: space-between;
color: white;
      padding: 10px 0px;
}

.rh-check-con3>h1{
    color: white;
    font-size: 18px;
    font-weight: 400;


}

.check-btn-1{
    width: 100%;

}
.check-btn-1>button{
    width: 100%;
    padding: 10px;
    background-color: white;
    border: none;
    color: black;
    border-radius: 5px;

}
.check-btn-1>button:active{
 transform: scale(0.9);
 /* transition: all 0.1s; */

}


@media (max-width: 900px) {
    .check-out-head-dv {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
    }
    .check-out-left-h {
        width: 80%;
    }
    .check-out-right-h {
        width: 80%;
    }


}


@media (max-width: 900px) {

    .check-out-left-h {
        width: 95%;
    }
    .check-out-right-h {
        width: 95%;
    }
   
}

.coupon-checkout{
    color: white;
    padding: 10px 0px;
    width: fit-content;
}
.apply-cc-check{
    background: white;
    padding: 10px 10px;
    border: none;
    color: black;
    border-radius: 5px;
    cursor: pointer;
}

.apply-cc-check:active{
    transform: scale(0.9);
    /* transition: all 0.1s; */
   
   }
.coupon-check-input{
    padding: 9px;

    border-width: 1px;
    border-style: solid;

    -webkit-appearance: none;
    box-sizing: border-box;
    width: 200px;
    font-weight: 400;
    border-radius: 5px;
    outline: 0;
    transition: all ease-in-out .3s;
}
.coupon-section-1{
    display: flex;
    gap: 20px;

    align-items: center;
}
.coupon-sec-2{
    margin-top: 20px;
    display: flex;
     flex-direction: column;
     gap: 10px;
}


.fadeInDown-check {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeInDown {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes fadeInDown {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    } 

    .orderscreen-after{


    }
    .gif-order{
        width: 200px;
        height: 200px;
        /* object-fit: cover; */
    }