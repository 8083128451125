.search-d3{

}
.search-d3>input{
    width: 100%;
    padding: 20px;
border-radius: 5px;
border: none;
background: white;

}
.search-d3>input:focus{
 outline: none;
}
.search1-d2{
width:95%;
}
.search1-d1{
    width: 100%;
    display: flex;
    justify-content: center;
}

.search-d3{

    display: flex;
    align-items: center;
    background: white;
    border-radius: 5px;
}

.search-result-section{
    color: white;
}
.result-data-p>p{
    /* color: white; */
    
}

.search1-d6{
    background-color: white;
    color: black;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.search1-d7{
    display: flex;
    /* align-items: center; */
    /* gap: 10px; */
    cursor: pointer;
    justify-content: space-between;
}

.search1-d8>p{
font-size: 15px;
}
.search1-d8>p:hover {
color: #F65000;
    }
.search1-d8{
    width: calc(100% - 50px);
}

.svg-search1{
    width: 50px;
    display: flex;
    justify-content: flex-end;

}

.head-cat-ser{

    font-weight: bold;
    font-size: 17px;
}