@media only screen and (max-width: 995px) {
    .main-gg {
      display: none !important;
    }
    .mobile-g {
      display: flex !important;
    }
  }
  @import url("https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap");
  
  @media only screen and (max-width: 1135px) {
    .build-t2 {
      overflow: unset;
      font-family: "Odibee Sans", cursive;
      /* line-height: 1; */
      font-style: italic;
      color: white;
      font-size: 23px;
      text-align: center;
    }
  }
  
  .main-gg {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-gh {
    width: 80%;
  }
  .headG-1 {
    gap: 10px;
  
    display: flex;
    justify-content: center;
  }
  .main-g11 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  .main-g11 {
    overflow: hidden;
  }
  
  .main-g11 img {
    max-width: 100%;
    vertical-align: top;
    position: relative;
    overflow: hidden;
  }
  .main-g11:hover img,
  .main-g11.hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
  }
  .pad-1 {
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }
  .text-hh {
    padding: 54px;
    position: absolute;
    z-index: 1;
  }
  .text-hh {
    padding: 54px;
    position: absolute;
    z-index: 1;
  }
  .center-t {
    text-align: center;
  }
  .build-t {
    color: #f65000;
    font-size: 15px;
    line-height: 2;
    font-weight: 600;
    /* transform: translateY(10px); */
    text-transform: uppercase;
    font-family: "Lato", Arial, sans-serif;
  }
  .build-t2 {
    font-family: "Odibee Sans", cursive;
    /* line-height: 1; */
    font-style: italic;
    color: white;
    font-size: 30px;
    text-align: center;
  }
  
  .build-h2 {
    overflow: unset;
    font-family: "Odibee Sans", cursive;
    /* line-height: 1; */
    font-style: italic;
    color: white;
    font-size: 30px;
  }
  .build-h3 {
    font-size: 18px;
    line-height: 2;
    font-weight: 100;
    font-family: "Lato", Arial, sans-serif;
    color: white;
  }
  
  .img-car2 {
    width: 500px;
    border-radius: 15px;
  }
  .pad-2 {
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .top-p {
    margin-top: 10px;
  }
  
  .text-hh1 {
    padding: 0px 76px;
    position: absolute;
    z-index: 1;
  }
  .build-t {
    color: #f65000;
    font-size: 15px;
    line-height: 2;
    font-weight: 600;
    /* transform: translateY(10px); */
    text-transform: uppercase;
    font-family: "Lato", Arial, sans-serif;
  }
  .pad-3 {
    border-radius: 15px;
  
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }
  
  .text-hh3 {
    padding: 54px;
    position: absolute;
    z-index: 1;
  }
  .build-t2 {
    overflow: unset;
    font-family: "Odibee Sans", cursive;
    /* line-height: 1; */
    font-style: italic;
    color: white;
    font-size: 30px;
    text-align: center;
  }
  .six-t {
    font-size: 40px;
    color: rgb(246, 80, 0);
  }
  .pad-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 15px;
  }
  
  .text-hh4 {
    /* padding: 54px; */
    position: absolute;
    z-index: 1;
  }
  
  
  
  
  .build-h4 {
    overflow: unset;
    font-family: "Odibee Sans", cursive;
  
    font-style: italic;
    color: white;
    font-size: 30px;
    font-size: 40px;
  }
  .mobile-g {
    display: none;
  }
  .mobile-g {
    display: flex;
    justify-content: center;
  }
  
  .mobile-g {
    display: none;
  }
  
  .gallery1 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 70%;
  }
  .figcapt {
    position: absolute;
  
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .figcapt > :nth-child(1) {
    color: #f65000;
    font-family: "Lato", Arial, sans-serif;
    font-size: 15px;
    line-height: 3;
    font-weight: 600;
    transform: translateY(10px);
    text-transform: uppercase;
  }
  .figcapt > :nth-child(2) {
    font-family: "Odibee Sans", cursive;
    /* line-height: 1; */
    font-style: italic;
    color: white;
    font-size: 19px;
    text-align: center;
  }
  
  .figcapt {
    transform: translate(201px, 6px);
    line-height: 1.5;
  }
  
  .nth3 {
    font-family: "Lato", Arial, sans-serif;
    color: white;
    font-size: 18px;
    line-height: 2;
    font-weight: 100;
  }
  .card-image > img {
    width: 350px;
    border-radius: 15px;
  }.capt1 {
    transform: translate(14px, 18px);
  }
  .figcapt60{
    font-family: "Odibee Sans", cursive;
    /* line-height: 5px; */
    font-style: italic;
    color: #f65000;
    font-size: 25px;
    text-align: center;
  }
  .last-c{
    display: flex;
      align-items: center;
      justify-content: center;
  }
  .figcapt22 {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .figcapt22 {
  
    line-height: 1.5;
  }
  .figcapt22> :nth-child(1) {
    color: #f65000;
    font-family: "Lato", Arial, sans-serif;
    font-size: 15px;
    line-height: 3;
    font-weight: 600;
    transform: translateY(10px);
    text-transform: uppercase;
  }
  .capt2 > :nth-child(2) {
    font-family: "Odibee Sans", cursive;
    /* line-height: 1; */
    font-style: italic;
    color: white;
    font-size: 29px;
    text-align: center;
  }
  








  * {
    margin: 0;
    padding: 0;
  
  }
  
  /* @import url("https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap"); */
  @import url("https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap");
  
  
  
  
  
  
  
  
  .img-cards2{
  border-radius: 15px;
    width: 500px;
  }
  .head-cf{
    display: flex;
    justify-content: center;
  }
  .head-cf2{
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .left-hcard{
    display: flex;
    align-items: center;
  }
  .right-hcard{
    display: flex;
    align-items: center;
  }
  .main-hf{
  
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .hov7 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  .hov7 img {
    max-width: 100%;
    vertical-align: top;
    position: relative;
    overflow: hidden;
  }
  
  .hov7:hover img,
  .hov7.hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
  }
  .img-cards{
    overflow: hidden;
    border-radius: 15px;
    overflow: hidden;
  }
  .card-texts1{
  
    position: absolute;
  }
  .card-texts1{
    position: absolute;
    padding: 0px 0px 0px 30px;
    line-height: 2;
    z-index: 5;
    }
    .flat-h{
      color: #f65000;
        font-size: 12px;
        /* line-height: 2; */
        font-weight: 600;
        /* transform: translateY(10px); */
        text-transform: uppercase;
        font-family: "Lato", Arial, sans-serif;
    }
    .pum-h{
  
      font-family: "Odibee Sans", cursive;
      /* line-height: 1; */
      font-style: italic;
      color: black;
      font-size: 25px;
      
    }
    .pum-h{
  
      line-height: 2rem;
    }
    @media only screen and (max-width: 1065px) {
  
      .pum-h {
        line-height: 1.5rem;
        font-size: 20px;
      }
      
      }
      .shop-b > span:hover {
        display: inline-block;
        transform: skew(21deg);
        color: white;
        /* transform: skew(338deg, 360deg); */
      }
      .shop-b {
        background: white;
        padding: 11px;
        border-radius: 4px;
        border: none;
        color: white;
        transform: skew(338deg, 360deg);
      }
      .shop-b > span {
        display: inline-block;
        transform: skew(21deg);
      
        color: black;
      }
      .snip:hover .shop-b,
  .snip.hover .shop-b {
    /* background: #f65000; */
    padding: 11px;
    border-radius: 4px;
    border: none;
  }
  
  
  @media only screen and (max-width: 995px) {
    .head-cf{
      display: none;
    }
  }
  @media only screen and (max-width: 1095px) {
    .snip:hover .shop-b, .snip.hover .shop-b {
      /* background: #f65000; */
      padding: 7px;
      border-radius: 4px;
      border: none;
    }}
  @media only screen and (max-width: 1095px) {
    .shop-b {
      background: white;
      padding: 7px;
      border-radius: 4px;
      border: none;
      color: white;
      transform: skew(338deg, 360deg);
  }
  
  
  }
  .card-m{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media only screen and (max-width: 995px) {
  
  
  .card-m1 {
    width: 70%;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .img-card1>img {
    width: 353px;
    border-radius: 15px;
  }
  }
  .card-m1{
    display: none;
    }
    .d1-3{
    
      display: flex;
      align-items: center;
    }
      


    .fo1 {
      display: flex;
      position: relative;
      width: 500px;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
      transition: all 0.5s;
      border-radius: 15px;
  }
  .foimg{
  
    width: 100%;
    transition: all 0.5s;

}
.foc {
  position: absolute;
  /* top: 0; */
  right: 20px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
  .four-ds{
    display: flex;
  }
  .foc-h1{
    color: #f65000;
    font-size: 15px;
    line-height: 2;
    font-weight: 600;
    /* transform: translateY(10px); */
    text-transform: uppercase;
    font-family: "Lato", Arial, sans-serif;
  }
  .foc-h2{
    overflow: unset;
    font-family: "Odibee Sans", cursive;
    /* line-height: 1; */
    font-style: italic;
    color: white;
    font-size: 30px;
  }
  .foc-p1{
    font-size: 18px;
    line-height: 2;
    font-weight: 100;
    font-family: "Lato", Arial, sans-serif;
    color: white;
  }
  .fo1:hover .foimg{
    transform: scale(1.1);
    overflow: hidden;
    transition: all 0.3s;

  }
  .lh-fc {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

  .rh-fc{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .head-f-card-s {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 40px;
}
.foc2 {
  position: absolute;
  /* top: 0; */
  left: 74px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.foc-h2-1{

  overflow: unset;
  font-family: "Odibee Sans", cursive;
  /* line-height: 1; */
  font-style: italic;
  color: white;
  font-size: 30px;
  text-align: center;
}
.sppa{
  font-size: 40px;
  color: rgb(246, 80, 0);
}
.foc3{
  position: absolute;
  /* top: 0; */
  right: 50px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.foc4{
  position: absolute;
  /* top: 0; */
  right: 0;
  left: 0;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1260px) {
  .fo1 {
  
    width: 355px;

  }
  .foc-h2 {
 
    font-size: 17px;

}
.sppa {
  font-size: 25px;
  color: rgb(246, 80, 0);
}
.foc-h2-1 {
  overflow: unset;
  font-family: "Odibee Sans", cursive;
  /* line-height: 1; */
  font-style: italic;
  color: white;
  font-size: 20px;
  text-align: center;
}
.foc-h1 {
  color: #f65000;
  font-size: 13px;
}
}
@media only screen and (max-width: 900px) {

  .head-f-card-s {
flex-wrap:wrap ;
  }
}

@media only screen and (max-width: 380px) {
  .fo1 {
    width: 300px;
}

}