.homet-g {
    color: #999;
    font-size: 15px;
    font-weight: 100;
    cursor: pointer;
  
}
.homet-g:hover {
    color: #f65000;

}
.arrow-g{
    color: #999;
    display: flex;


}
.homeg-1{
   color: white;
    font-size: 15px;
    font-weight: 100;
   
}
.font77{
    font-family: "Lato", Arial, sans-serif;
}
.hom-g4{
    display: flex;

    margin: 25px 75px;

    gap: 5px;

}

.font-io{
    margin-bottom: 20px;
    font-family: "Odibee Sans", cursive;
    color: white;
    font-style: italic;
}
.fornn{
    padding-right: 15%;
    color: white;
    display: flex;
    flex-direction: column;

}
.for-input{
    
    /* width: 23%; */
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: white;
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    outline: none;
    resize: none;
    /* width: 60%; */
  
}
.for-input:focus{
    outline: none;

}
.check-rem{
    flex-wrap: wrap;

    display: flex;
    margin: 20px 0px;
    justify-content: space-between;
}
.fle-form{

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.form-hd{
    
    width: 40%;
}
.label-f{

    margin-bottom: 10px;
}
.label-f1{

    margin-bottom: 10px;
}


.fle-dd{
    margin-top: 10px;

    display: flex;
    flex-direction: column;
}
.fle-dd1{
    margin-top: 25px;

    display: flex;
    flex-direction: column;
}
.lost-pp{
    
    text-decoration: underline;

    transition: 0.3s;
}
.lost-pp:hover{
    color: #f65000;
cursor: pointer;
}
.p-te4{
    
    margin-top: 10px;
    line-height: 1.5;
    color: #999;
}

.ju-8{

    margin-top: 20px;
}
.btn-loginn{
    color: black;
  
    padding: 11px 88px;
    transform: skewX(-10deg) scaleX(.955);
    border-radius: 5px;
}
.btn-loginn:hover{
background-color: #f65000;
color: white;
}
.bth-g{
margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-loginn1{
  color: black;
    padding: 11px 88px;
    transform: skewX(-10deg) scaleX(.955);
    border-radius: 5px;
    border: none;
}
.btn-loginn1:hover{
background-color: #f65000;
color: white;
}
.bth-g1{
margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 730px) {

    .fle-form {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    gap: 3rem;
    }
    .fornn {
        padding-right: 0% !important;
        color: white;
        display: flex;
        flex-direction: column;
    }
    .form-hd {
        width: 80%;
    }

}