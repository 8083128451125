.ProductListMain{
    /* width: 80%;
    display: grid; */
    grid-gap: 20px;

    /* grid-template-columns: 1fr 3fr;
    grid-template-areas: 
      "ProductListMainLeft ProductListMainRight" */
}


.ProductListMainLeft{
    grid-area: ProductListMainLeft;
    background-color: white;
    height: 100%;
    border-radius: 15px;
}


.ProductListMainRight{
    grid-area: ProductListMainRight;
    height: 100%;

}
.ProductList{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    
}

@media (max-width:1000px) {
    .ProductListMain{
        width: 100%;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 100%;
        grid-template-areas: 
          "ProductListMainRight"
    }
    
}

.btn-Load-more{

    border: 1px solid;
    background: transparent;
    color: black;
    padding: 5px;
    background: white;
    border-radius: 4px;
}
.btn-view-div {
    display: flex;
    justify-content: center;
}

.pro-list-data {
    width: 100%;
    display: flex;
    justify-content: center;
}
@media (max-width:600px) {


.ProductList{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
    
}

}